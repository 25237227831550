html {
  font-size: 62.5%;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.text-danger {
  color: var(--red);
}

.uppercase {
  text-transform: uppercase;

  &::placeholder {
    text-transform: none;
  }
}
