/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
// @import '~@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

@import 'theme/variables';
@import 'theme/curbnturf-variables';

@import 'theme/toast';

/* Custom CSS */
/* replaced scss/bootstrap */
@import 'theme/close';
@import 'theme/dropdown';
@import 'theme/carousel';

/* own */
@import 'theme/reboot';
@import 'theme/ionic-components';
@import 'theme/components';
@import 'theme/fonts';
@import 'theme/here-maps';
@import 'theme/navigation';
@import 'theme/scrollbars';
@import 'theme/toggle-switch';
@import 'theme/type';
@import 'theme/badge';

/* from webflow */
@import 'theme/wf-extra-pre';
@import 'theme/webflow';
@import 'theme/wf-curbnturf';
@import 'theme/wf-extra';
@import 'theme/cnt-modal';

html,
body,
curbnturf-root {
  height: 100%;
}

html {
  font-size: 100%;
}

div.listing-content-wrapper {
  margin-bottom: 72px;
}

/** CurbNTurf Color Classes **/
.ion-color-orange {
  --ion-color-base: var(--ion-color-orange);
  --ion-color-base-rgb: var(--ion-color-orange-rgb);
  --ion-color-contrast: var(--ion-color-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-orange-shade);
  --ion-color-tint: var(--ion-color-orange-tint);
}

ion-card {
  font-family: proxima-nova, sans-serif;

  ion-card-header {
    flex-direction: row;
  }
}

ion-card.dashboard {
  font-family: proxima-nova, sans-serif;
  border: 1px solid #d8d6d6;
  box-shadow: none;
  margin-left: 0;
  margin-right: 0;
  border-radius: 0;
  color: black;

  ion-card-content p {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  ion-card-header {
    font-family: rift-soft, sans-serif;
    border-bottom: 1px solid #d8d6d6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }

  ion-card-title {
    font-size: 1.4em;
    font-weight: 600;
    letter-spacing: 1.5px;
  }

  .fine-print {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.4em;
  }
}

.content-wrapper.content {
  margin-bottom: 72px;
}

/* Photo Viewer */
ion-modal.photo-viewer-modal {
  --width: 100vw;
  --height: 100vh;
  --max-width: 100vw;
  --mag-height: 100vh;
}

input,
textarea,
select {
  background-color: var(--ion-background-color);
}

.currency-rounded-left {
  border-radius: 9px 0 0 9px !important;
}

.currency-rounded-right {
  border-radius: 0 9px 9px 0 !important;
}

vg-controls {
  > * {
    align-items: center;
  }
}

.course-control-wrapper {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 991px) {
  div.listing-content-wrapper {
    margin-top: 6px;
  }
}

@media screen and (max-width: 767px) {
  div.listing-content-wrapper {
    margin-top: 6px;
    padding-top: 0;
  }
}
