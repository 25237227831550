.photo-modal {
  .modal-wrapper {
    width: 100vw;
    height: calc(43vw + 44px);
  }
}

pwa-action-sheet {
  z-index: 22222;
}

pwa-camera-modal-instance {
  z-index: 22223;
}

.auth-modal {
  ion-content::part(background) {
    background-color: var(--green);
  }
}

ion-modal.center ion-content::part(scroll) {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) and (min-height: 600px) {
  .photo-modal {
    .modal-wrapper {
      max-width: 1270px;
      width: 100vw;
      max-height: 590px;
      height: calc(43vw + 44px);
    }
  }

  .auth-modal {
    .modal-wrapper {
      min-height: 642px;
      max-height: 100vh;
    }

    &.recover {
      .modal-wrapper {
        height: 284px;
        min-height: 284px;
      }
    }

    &.register {
      .modal-wrapper {
        min-height: 612px;
      }
    }
  }

  .confirm-modal {
    .modal-wrapper {
      height: 224px;
      min-height: 224px;
    }
  }

  .enable-modal {
    .modal-wrapper {
      height: 240px;
      min-height: 240px;
    }
  }

  .add-listing-modal {
    .modal-wrapper {
      height: 432px;
      min-height: 432px;
    }
  }

  .new-rv-modal {
    .modal-wrapper {
      height: 262px;
      min-height: 262px;
    }
  }

  .affiliate-modal {
    .modal-wrapper {
      max-height: 560px;
    }
  }
}
