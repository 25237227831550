.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;
}

.w-checkbox::before {
  content: ' ';
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox::after {
  content: ' ';
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: var(--secondary);
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: var(--secondary);
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

// keep for now
.w-layout-grid {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.w-layout-layout {
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  grid-auto-columns: 1fr;
  justify-content: center;
  padding: 20px;
}

.w-layout-cell {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.w-layout-vflex {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.w-form-formradioinput--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-width: 4px;
  border-color: #3898ec;
}

body {
  color: #454545;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4em;
}

h1 {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 72px;
  font-weight: 700;
  line-height: 1em;
}

h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 1em;
}

h3 {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 24px;
  font-weight: 700;
  line-height: 1em;
}

h4 {
  margin-top: 0;
  margin-bottom: 6px;
  margin-right: 9px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1em;
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}

p {
  margin-bottom: 12px;
  line-height: 1.6em;
}

li {
  line-height: 1.6em;
}

.bottom-line {
  width: 12px;
  height: 2px;
  background-color: #454545;
  border-radius: 5px;
  margin-top: -2px;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: rotate(-45deg);
}

.toggle-active {
  width: 100%;
  height: 100%;
  text-align: center;
  border-radius: 100px;
  flex-flow: column wrap;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  display: flex;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.toggle-label {
  z-index: 2;
  height: 100%;
  margin-bottom: 6px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.toggle-wrap {
  width: 54px;
  height: 30px;
  background-color: #454545;
  border-radius: 25px;
  flex: none;
  margin-bottom: 0;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
  display: block;
  position: relative;
}

.toggle-wrap.rv {
  width: 54px;
  height: 30px;
  max-height: 30px;
  max-width: 54px;
  flex: none;
  margin-left: 6px;
  margin-right: 9px;
}

.toggle-wrap.rv:hover {
  transform: translate(0);
}

.toggle {
  z-index: 2;
  width: 26px;
  height: 26px;
  background-color: #fff;
  border-radius: 100px;
  margin-top: 2px;
  margin-left: 2px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.active-overlay {
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #fff;
  border-radius: 100px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: -3px 0 3px #454545;
}

.toggle-ticker {
  z-index: 3;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  margin-top: 0;
  margin-left: 0;
  padding-right: 0;
}

.night-overlay {
  width: 100%;
  height: 100%;
  opacity: 0;
  background-image: linear-gradient(135deg, #484891, #49498a);
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.top-line {
  width: 12px;
  height: 2px;
  background-color: #454545;
  border-radius: 5px;
  margin-top: 0;
  transform: rotate(45deg);
}

.heading-2 {
  color: #454545;
  text-align: left;
  letter-spacing: -0.2px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  line-height: 1em;
}

.heading-2.center {
  text-align: center;
}

.heading-2.center-tundora {
  color: #454545;
  text-align: center;
}

// keep for now
.heading-2.white {
  color: #fff;
  text-align: left;
}

.form-block {
  flex-direction: column;
  padding: 0;
  display: flex;
}

.form-block.search {
  height: 100%;
}

.wizard-form {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: rgba(0, 0, 0, 0);
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: block;
}

.wizard-form.search {
  display: block;
}

.wizard-form.filter {
  padding-bottom: 0;
}

.body-copy {
  letter-spacing: -0.2px;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.cnt-button {
  height: 36px;
  min-width: 96px;
  color: #454545;
  letter-spacing: 1.5px;
  background-color: #fff;
  border: 1px solid #454545;
  border-radius: 50vh;
  flex-direction: row;
  flex: none;
  justify-content: center;
  align-items: center;
  padding-left: 18px;
  padding-right: 18px;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
  text-decoration: none;
  display: flex;
}

.cnt-button.active,
.cnt-button:active {
  color: #fff;
  background-color: #454545;
  border-color: rgba(0, 0, 0, 0);
}

.cnt-button.upload-image {
  width: 240px;
  height: 240px;
  border-radius: 120px;
  margin-left: 0%;
  margin-right: 0%;
}

.cnt-button.site-image-upload {
  width: 100%;
  height: 100%;
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
}

// keep for now
.cnt-button.nandor {
  height: 48px;
  margin-top: 18px;
  margin-right: 0;
  margin-left: 0;
  border-width: 2px;
  border-color: #fff;
  border-radius: 50vh;
  background-color: #465d50;
  color: #fff;
}

// keep for now
.cnt-button.nandor:active {
  border-width: 0;
  background-color: #fff;
  color: #465d50;
}

.cnt-button.filter {
  flex: none;
  margin-left: 9px;
  margin-right: 9px;
}

.cnt-button.filter-button {
  height: 18px;
  flex: none;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 6px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.cnt-button.categories {
  flex: none;
  margin: 6px 3px;
}

.cnt-button.m-18px {
  margin-top: 18px;
}

.cnt-button.trip {
  min-width: auto;
  margin-left: 6px;
  margin-right: 6px;
}

.cnt-button-copy {
  height: 36px;
  max-width: 100%;
  text-align: center;
  letter-spacing: 1.5px;
  border: 1px #454545;
  border-radius: 0;
  flex: none;
  justify-content: center;
  align-items: center;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
  line-height: 1em;
  text-decoration: none;
  display: flex;
}

.cnt-button-copy:active {
  color: #fff;
}

.cnt-button-copy.filter {
  font-size: 14px;
}

.cnt-button-icon {
  width: 18px;
  flex: none;
  margin-top: 3px;
  margin-right: 6px;
  padding-bottom: 0;
}

.cnt-button-icon.addition,
.cnt-button-icon.subtraction {
  margin-top: 6px;
}

// keep for now
.cnt-button-icon.apple-auth {
  width: 24px;
  margin-right: 12px;
}

.cnt-button-icon._24px {
  width: 24px;
}

.button-wrapper {
  flex-direction: row;
  flex: 0 auto;
  justify-content: center;
  align-items: stretch;
  padding-top: 12px;
  padding-bottom: 0;
  display: block;
}

.button-wrapper._18px {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.html-embed {
  padding-top: 0;
  display: block;
  transform: rotate(0);
}

.html-embed.left-chevron {
  display: block;
  transform: rotate(90deg);
}

.html-embed.right-chevron {
  display: block;
  transform: rotate(-90deg);
}

.semi-bold {
  color: #454545;
  letter-spacing: -0.2px;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  text-decoration: none;
}

.text-block {
  color: #454545;
  line-height: 1em;
}

.cnt-input-wrapper {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 2%;
  margin-right: 2%;
  display: flex;
}

.text-field-label {
  height: 36px;
  background-color: #fff;
  border: 2px solid #454545;
  border-radius: 9px;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.text-field-rounded-left {
  width: 64px;
  height: 36px;
  min-height: 36px;
  border: 1px solid #454545;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  flex: 1;
  align-self: center;
  margin-bottom: 0;
  overflow: visible;
}

.input-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 12px -2%;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

.input-w-label {
  width: 272px;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-bottom: 12px;
  display: flex;
}

.input-w-label.listing {
  width: auto;
  justify-content: flex-end;
  margin-top: 12px;
  margin-bottom: 12px;
  padding-bottom: 0;
}

.input-w-label.tax {
  justify-content: flex-start;
}

.text-field-rounded {
  width: 30%;
  border: 1px solid #454545;
  border-radius: 9px;
  flex: none;
  margin-bottom: 0;
  margin-right: 6px;
}

.axle-count {
  width: 80%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.wizard-nav-btn {
  width: 60%;
  min-width: 240px;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: row;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-end;
  align-items: center;
  display: grid;
}

.wizard-nav-wrapper {
  justify-content: flex-end;
  align-items: center;
  padding-top: 36px;
  display: flex;
}

.wizard-info-card {
  max-width: 20%;
  min-width: 300px;
  background-color: #fff;
  // background-image: url('../images/Topo_Large_Tundora_10.svg');
  // background-position: 50%;
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-attachment: fixed;
  border: 1px solid #454545;
  border-radius: 9px;
  padding: 36px;
}

// keep for now
.spacer {
  width: 48px;
  height: 1px;
}

.current-status {
  width: 53.8%;
  min-height: 0.5vh;
  background-color: var(--primary);
  border-radius: 0 20px 20px 0;
}

.image {
  display: block;
}

// keep for now
.cnt-logo {
  overflow: hidden;
  width: 3.5vh;
  margin-top: 1vh;
  margin-left: 1vh;
}

.source-current-status {
  width: 0;
}

.text-input {
  height: 48px;
  // color: #000;
  // letter-spacing: 1.5px;
  border: 1px solid #454545;
  border-radius: 9px;
  // font-family: rift-soft, sans-serif;
  // font-weight: 600;
}

.text-input.search {
  width: 36vw;
  height: 48px;
  margin-top: 18px;
  margin-bottom: 0;
}

.text-input.trinidad {
  height: 48px;
  letter-spacing: 0;
  border-width: 1px;
  border-color: var(--primary);
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
}

.text-input.caption {
  margin-bottom: 0;
}

.image-upload-outer {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  position: static;
}

.terrain-current-status {
  width: 14.3%;
  border-top-right-radius: 50vh;
  border-bottom-right-radius: 50vh;
}

.image-upload-wrapper {
  width: 100%;
  max-width: 240px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  display: flex;
  position: static;
}

.previous-button {
  flex: 1;
  text-decoration: none;
}

.next-button {
  cursor: pointer;
  flex: 1;
  text-decoration: none;
}

.circle-photo {
  width: 240px;
  height: 240px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.upload-image-wrapper {
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  position: relative;
}

.upload-image-wrapper.site-image {
  padding-top: 0;
  padding-bottom: 40%;
}

.upload-image-wrapper.site-image._18px {
  margin-top: 18px;
}

.upload-image-wrapper.site-image._18-px {
  margin-bottom: 18px;
}

.icon-image {
  width: 36px;
  height: 36px;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.upload-image-inner {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 18px;
  display: flex;
}

.heading-3 {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 6px;
  font-size: 18px;
  line-height: 1em;
}

.wizard-info-card-controls {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  display: none;
}

._18-px-spacer {
  height: 18px;
  text-align: left;
}

.text-span {
  font-weight: 600;
}

.toggle-wrapper {
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
  text-decoration: none;
  display: flex;
}

.toggle-wrapper.select-all {
  justify-content: flex-end;
  padding-left: 12px;
}

.toggle-wrapper.setup {
  width: 60%;
}

.phone-current-status {
  width: 7.1%;
  min-height: 0.5vh;
  background-color: var(--primary);
  border-top-right-radius: 50vh;
  border-bottom-right-radius: 50vh;
}

.activities-current-status {
  width: 28.6%;
  min-height: 0.5vh;
  background-color: var(--primary);
  border-top-right-radius: 50vh;
  border-bottom-right-radius: 50vh;
}

.amenities-current-status {
  width: 21.4%;
  min-height: 0.5vh;
  background-color: var(--primary);
  border-top-right-radius: 50vh;
  border-bottom-right-radius: 50vh;
}

.isolation-current-status {
  width: 35.7%;
  min-height: 0.5vh;
  background-color: var(--primary);
  border-top-right-radius: 50vh;
  border-bottom-right-radius: 50vh;
}

.rvs-current-status {
  width: 42.9%;
  min-height: 0.5vh;
  background-color: var(--primary);
  border-top-right-radius: 50vh;
  border-bottom-right-radius: 50vh;
}

.details-current-status {
  width: 50%;
  min-height: 0.5vh;
  background-color: var(--primary);
  border-top-right-radius: 50vh;
  border-bottom-right-radius: 50vh;
}

.list-item {
  background-image: url('/img/wf/Bullet-arrow.svg');
  background-position: 0 3px;
  background-repeat: no-repeat;
  background-size: auto;
  padding-left: 12px;
  font-size: 14px;
  list-style-type: none;
  transition: opacity 0.2s;
}

.list {
  margin-bottom: 6px;
  padding-left: 18px;
}

.rv-button-copy {
  color: #454545;
  text-align: center;
  letter-spacing: 1.5px;
  padding-top: 6px;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
  line-height: 1em;
}

.rv-button {
  opacity: 0.5;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-left: 2%;
  margin-right: 2%;
  display: flex;
  position: relative;
}

.rv-button:active {
  opacity: 1;
}

.rv-button-icon {
  width: 100%;
}

.rv-button-icon.inactive-rv {
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
  right: 0;
}

.active-rv {
  opacity: 0;
}

.active-rv:active {
  opacity: 1;
  display: block;
}

.price-current-status {
  width: 57.1%;
  min-height: 0.5vh;
  background-color: var(--primary);
  border-top-right-radius: 50vh;
  border-bottom-right-radius: 50vh;
}

.dropdown-toggle {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 1px solid #454545;
  border-radius: 9px;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  display: flex;
}

.dropdown-toggle.w--open {
  background-color: #fff;
}

.dropdown-toggle.datepicker {
  border-color: #d8d6d6;
}

.dropdown-toggle.profile {
  margin-left: 0;
}

.dropdown-toggle.m-12px {
  margin-top: 12px;
}

.dropdown-textblock {
  letter-spacing: 1.5px;
  flex: 1;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
}

.dropdown {
  width: 100%;
  height: 48px;
  margin-bottom: 9px;
}

.dropdown-link {
  letter-spacing: 1.5px;
  background-color: #fff;
  border-left: 1px solid #454545;
  border-right: 1px solid #454545;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
}

.dropdown-link-end {
  letter-spacing: 1.5px;
  background-color: #fff;
  border-bottom: 1px solid #454545;
  border-left: 1px solid #454545;
  border-right: 1px solid #454545;
  border-bottom-right-radius: 9px;
  border-bottom-left-radius: 9px;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
}

.element-inner {
  width: 36px;
  height: 36px;
  border: 2px solid #454545;
  border-radius: 9px;
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.element-bold {
  font-weight: 600;
}

.text-field-rounded-right {
  width: 64px;
  height: 36px;
  border: 1px solid #454545;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
  flex: none;
  margin-bottom: 0;
}

.price-section {
  justify-content: center;
  align-items: center;
  display: flex;
}

.price-section.grid {
  grid-column-gap: 12px;
  grid-row-gap: 0px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.icon-w-label {
  justify-content: flex-start;
  align-items: center;
  margin-top: 6px;
  margin-bottom: 12px;
  display: flex;
}

.icon-w-label.grid {
  justify-content: flex-start;
}

.sum-icon {
  width: 36px;
  height: 36px;
  flex: none;
}

.text-block-13 {
  flex: none;
  padding-left: 9px;
  font-weight: 700;
}

.sum-wrapper {
  justify-content: center;
  align-items: center;
  padding-left: 9px;
  display: flex;
}

.per-night {
  padding-left: 6px;
  padding-right: 6px;
}

.div-block-17 {
  width: 36px;
  height: 36px;
  text-align: center;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  padding-left: 0;
  display: block;
}

.fine-print {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2em;
}

.fine-print.white-left {
  color: #fff;
}

.fine-print.centered {
  text-align: center;
}

.fine-print.trinidad {
  padding-top: 0;
  padding-bottom: 0;
}

.fine-print.offsite {
  cursor: pointer;
  border-top: 1px solid #454545;
  padding-top: 6px;
  padding-bottom: 6px;
}

.fine-print.center-red {
  color: var(--primary);
  text-align: center;
}

.fine-print.listing {
  padding-top: 6px;
}

.fine-print.italic {
  font-style: italic;
}

.text-span-2 {
  font-weight: 600;
}

.horz-line {
  border-top: 1px solid #454545;
  margin-top: 12px;
}

// keep for now
.horz-line.white {
  padding-bottom: 0;
  border-style: none none solid;
  border-width: 0 0 2px;
  border-color: #fff;
}

.horz-line._18px {
  border-top-color: #d8d6d6;
}

.text-block-15 {
  font-size: 12px;
}

.semi-bold,
.text-span-4,
.text-span-5,
.text-span-6 {
  font-weight: 600;
}

.booking-current-status {
  width: 64.3%;
  min-height: 0.5vh;
  background-color: var(--primary);
  border-top-right-radius: 50vh;
  border-bottom-right-radius: 50vh;
}

._2up-dropdown-wrapper {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
  display: grid;
}

.left-column-wrapper {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 0;
  display: flex;
}

.left-column-wrapper.listing {
  margin-left: 0;
  margin-right: 0;
}

.title-2up {
  padding-bottom: 6px;
}

.right-column-wrapper {
  width: 100%;
}

.right-column-wrapper.listing {
  margin-left: 0;
  margin-right: 0;
}

._1up-outer-wrapper {
  padding-top: 6px;
  padding-bottom: 6px;
}

._1up-outer-wrapper.caption {
  border: 1px solid #d8d6d6;
  border-top-style: none;
  padding-left: 11px;
  padding-right: 11px;
}

.title {
  white-space: normal;
  cursor: auto;
  object-fit: fill;
  padding-bottom: 6px;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  position: static;
}

.rules-current-status {
  width: 71.3%;
  min-height: 0.5vh;
  background-color: var(--primary);
  border-top-right-radius: 50vh;
  border-bottom-right-radius: 50vh;
}

.link {
  color: var(--primary);
  font-style: italic;
  font-weight: 600;
  text-decoration: none;
}

.checkbox {
  border-color: #454545;
  flex: none;
  margin: 3px 6px 0 -18px;
}

.checkbox-label {
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 1.4em;
}

.checkbox-field {
  align-items: flex-start;
  margin-bottom: 0;
  margin-left: 25px;
  margin-right: 25px;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

.checkbox-field.tax {
  margin-left: 18px;
}

.text-area {
  border: 1px solid #454545;
  border-radius: 9px;
}

._12-px-spacer {
  height: 12px;
}

._6-px-spacer {
  height: 6px;
}

.add-rules-wrapper {
  display: none;
}

.location-current-status {
  width: 78.6%;
  min-height: 0.5vh;
  background-color: var(--primary);
  border-top-right-radius: 50vh;
  border-bottom-right-radius: 50vh;
}

.l-lat-text-field {
  border: 1px solid #454545;
  border-radius: 9px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 12px;
}

.landowner-wrapper {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  text-align: left;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  display: grid;
}

.landowner-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.landowner-center.accordion {
  justify-content: flex-start;
  margin-top: 12px;
  margin-bottom: 18px;
}

.name-current-status {
  width: 85.7%;
  min-height: 0.5vh;
  background-color: var(--primary);
  border-top-right-radius: 50vh;
  border-bottom-right-radius: 50vh;
}

.site-description {
  min-height: 320px;
  border: 1px solid #454545;
  border-radius: 9px;
}

.site-description.personal {
  min-height: 240px;
}

.photo-current-status {
  width: 92.6%;
  min-height: 0.5vh;
  background-color: var(--primary);
  border-top-right-radius: 50vh;
  border-bottom-right-radius: 50vh;
}

.site-image-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.publish-current-status {
  width: 100%;
  min-height: 0.5vh;
  background-color: var(--primary);
  border-top-right-radius: 50vh;
  border-bottom-right-radius: 50vh;
}

.logo-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

.number {
  width: 24px;
}

._24-px-font {
  text-align: center;
  margin-right: 6px;
  padding-right: 0;
  font-size: 24px;
  font-weight: 700;
}

.selection-wrapper {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.rift-soft {
  letter-spacing: 1.5px;
  font-family: rift-soft, sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.rift-soft.list {
  color: #454545;
  margin-bottom: 2px;
  padding-left: 3px;
}

.rift-soft.map {
  color: #454545;
  padding-left: 6px;
}

.rift-soft._14px {
  font-size: 14px;
}

.selection-section {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.selection-section.filter {
  margin-top: 12px;
  margin-bottom: 12px;
}

.nandor-background {
  max-height: none;
  min-height: 100vh;
  opacity: 1;
  background-color: #465d50;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  transition: opacity 0.5s;
  display: flex;
  position: relative;
}

// keep for now
.auth-wrapper {
  display: flex;
  width: 400px;
  padding: 48px;
  flex-direction: column;
  justify-content: center;
}

// keep for now
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0 none #fff;
  background-color: transparent;
  opacity: 1;
}

// keep for now
.auth-logo-wrapper {
  padding: 48px;
}

// keep for now
.bold-text {
  color: #fff;
  text-decoration: none;
}

// keep for now
.auth-link {
  color: #fff;
  text-decoration: none;

  &:hover {
    color: var(--white-50);
    text-decoration: none;
  }
}

// keep for now
.auth-inner-wrapper {
  display: block;
  padding: 0 2px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

._48px-text-field {
  height: 48px;
  color: #fff;
  background-color: #465d50;
  border: 1px solid #fff;
  border-radius: 9px;
  margin-top: 18px;
  margin-bottom: 0;
  display: inline-block;
}

// keep for now
.new {
  color: #fff;
}

.white {
  color: #fff;
  text-align: center;
  align-self: center;
}

.white.grow {
  margin-bottom: 40px;
}

.dropdown-icon {
  width: 12px;
  height: 12px;
  justify-content: center;
  align-items: center;
  line-height: 1.4em;
  display: block;
  position: absolute;
  bottom: 20px;
  right: 12px;
}

.dropdown-icon.datepicker {
  margin-top: 12px;
  position: static;
  bottom: auto;
  right: auto;
}

.info-icon {
  display: block;
}

.text-span-7 {
  font-style: italic;
}

.text-field-inner-wrapper {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

.rv-specs-field {
  border: 1px solid #454545;
  border-radius: 9px;
  margin-bottom: 0;
}

.text-block-18 {
  width: 100%;
  flex: none;
}

.nav-search-wrapper {
  height: 100%;
  align-items: center;
  display: flex;
}

.nav-bar {
  // keep to allow fixed menu bar
  position: fixed;
  top: 0;
  z-index: 2000;
  width: 100%;
  height: 72px;
  background-color: #fff;
  border-bottom: 1px solid #d8d6d6;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5%;
  padding-right: 1.5%;
  display: flex;
}

.nav-link {
  height: 100%;
  color: #454545;
  border-bottom: 3px solid rgba(0, 0, 0, 0);
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none;
  display: flex;
}

.nav-link:hover {
  border: 0 solid #000;
  border-bottom: 3px solid var(--primary);
  font-weight: 400;
}

.nav-link.trinidad {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  margin-bottom: 0;
  padding: 4px 36px;
  font-weight: 600;
}

.nav-link.trinidad:hover {
  font-weight: 700;
}

.nav-link.trinidad.w--current {
  margin-bottom: 0;
}

.nav-link.trinidad.reviews {
  justify-content: flex-start;
  padding-left: 0;
  padding-right: 36px;
  font-weight: 400;
}

.icon-search {
  width: 18px;
  font-size: 19px;
  position: absolute;
  top: 28px;
  bottom: auto;
  left: 12px;
  right: auto;
}

.icon-search.filter {
  margin-left: 6px;
  padding-top: 14px;
  top: 0;
  bottom: 0;
  left: 0;
  right: auto;
}

.search-wrapper {
  height: 100%;
  border: 1px #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  position: relative;
}

.brand {
  width: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cnt-hero-wrapper {
  width: 100%;
  height: 28vw;
  max-width: none;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.cnt-button-secondary {
  color: #454545;
  text-align: center;
  letter-spacing: 1.5px;
  background-color: #fff;
  border: 1px solid #454545;
  border-radius: 3px;
  flex: none;
  padding-left: 12px;
  padding-right: 12px;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
  text-decoration: none;
  display: block;
}

// temporarily commenting this out until we get an update from Dustin
// .cnt-button-secondary:hover {
//   background-color: #484848;
//   color: #fff;
// }

.cnt-button-secondary.hero {
  width: auto;
  letter-spacing: 1.5px;
  border-style: none;
  border-color: #fff;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
  display: inline;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.price-strip {
  height: 48px;
  opacity: 0.9;
  color: #fff;
  background-color: var(--primary);
  justify-content: space-between;
  align-items: center;
  padding-left: 18px;
  padding-right: 18px;
  display: flex;
  position: static;
}

.booking-info-card {
  background-color: #fff;
}

.divider-line {
  width: 100%;
  height: 1px;
  color: #707070;
  background-color: #d8d6d6;
  margin-top: 12px;
  margin-bottom: 12px;
}

.divider-line.profile {
  margin-top: 0;
}

.divider-line.dropdown {
  margin-top: 0;
  margin-bottom: 0;
}

.booking-section {
  flex: 1;
  margin-top: -48px;
}

.form-vehicles-tents {
  margin-top: 18px;
  margin-bottom: 0;
}

.datepicker-wrapper {
  background-color: #fff;
  border: 1px solid #d8d6d6;
  flex-direction: column;
  padding: 20px;
  display: flex;
}

.form-container {
  width: 100%;
  height: 48px;
  border: 1px solid #d8d6d6;
  border-radius: 9px;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.form-container.datepicker {
  height: 48px;
  color: #707070;
  border-style: none;
}

.rate-type {
  font-size: 14px;
  font-weight: 300;
}

.div-block-2 {
  flex: 0 auto;
  align-items: stretch;
  display: flex;
}

.form-datepicker {
  flex-direction: column;
  flex: 1;
  padding-top: 12px;
  display: flex;
}

.anchor-link-wrapper {
  width: auto;
  height: 48px;
  border-bottom: 1px solid #454545;
  flex: 0 auto;
  align-items: center;
  display: flex;
}

.share-wrapper {
  text-align: center;
  border: 1px solid #d8d6d6;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  padding: 20px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.booking-sidebar {
  z-index: 5;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 72px;
}

.price {
  margin-right: 5px;
  font-size: 24px;
}

.guest-copy {
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
  display: flex;
}

.review-header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.listing-content-section {
  border: 1px #000;
  border-top: 1px solid #d8d6d6;
  flex-direction: column;
  align-items: stretch;
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
}

.listing-content-section.listing-name {
  border-style: none;
  border-color: #000;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.listing-content-section.top,
.listing-content-section.no-border {
  border-style: none;
  border-color: #000;
}

.feature-name {
  margin-right: 5px;
  line-height: 1.2em;
  display: inline-block;
}

.feature-name.bold {
  display: inline;
}

.user-image {
  width: 72px;
  height: 72px;
  // background-image: url('../images/Aaron-R_1Aaron R.jpg');
  // background-position: 50%;
  // background-size: cover;
  border-radius: 50vh;
  // flex: 1;
  margin-top: 6px;
  margin-bottom: 6px;
}

.user-image._60px {
  width: 60px;
  height: 60px;
  margin-bottom: 0;
}

// .user-image.teddy {
//   background-image: url('../images/President_Roosevelt.jpg');
//   background-position: 50%;
//   background-size: auto;
// }

// .user-image.james {
//   background-image: url('../images/JamesJ_1JamesJ.jpg'), url('../images/Aaron-R_1Aaron R.jpg');
//   background-position: 50%, 50%;
//   background-size: auto, cover;
// }

.copy-light {
  color: #707070;
  font-size: 14px;
  text-decoration: none;
  display: inline;
}

.copy-light:hover {
  font-weight: 700;
}

.host-reviews {
  margin-bottom: 12px;
  display: flex;
}

.reviews-badge {
  color: #fff;
  letter-spacing: 1px;
  background-color: var(--primary);
  border-radius: 6px;
  margin-right: 10px;
  padding: 5px 10px;
}

.divider {
  width: 1px;
  height: 20px;
  background-color: #d8d6d6;
  margin-left: 10px;
  margin-right: 10px;
}

.text-block-19 {
  margin-right: 10px;
}

.guest-content {
  align-items: center;
  display: flex;
}

.pagination-wrapper {
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.feature-row {
  margin-bottom: 6px;
  display: flex;
}

.reviews-badge-wrapper {
  align-items: center;
  margin-right: 30px;
  display: flex;
}

.icon-report {
  width: 12px;
  color: #707070;
  margin-right: 5px;
  font-size: 15px;
  display: inline;
}

.ratings-row {
  border-bottom: 1px solid #d8d6d6;
  justify-content: space-between;
  margin-bottom: 12px;
  display: flex;
}

.listing-content-inner-wrapper {
  width: 65%;
  height: 100%;
  padding-right: 20px;
}

.pagination {
  width: 30px;
  height: 35px;
  color: #454545;
  border: 1px solid #d8d6d6;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-size: 16px;
  display: flex;
}

.pagination.active {
  color: #484848;
  background-color: #edefed;
}

.review-copy {
  margin-top: 20px;
}

.location {
  padding-top: 3px;
  line-height: 1em;
  display: block;
}

.image-6 {
  border-radius: 100px;
}

.helpful-inner {
  height: 40px;
  border: 1px solid #d8d6d6;
  border-radius: 3px;
  align-items: center;
  margin-left: 20px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
}

.anchor-div {
  z-index: -1;
  width: auto;
  height: 50px;
  margin-top: -49px;
  position: absolute;
}

.hero-image {
  width: 67%;
  height: 100%;
  background-color: #454545;
  // background-image: url('../images/CurbNTurf_1.jpg');
  // background-position: 50%;
  // background-size: cover;
}

.secondary-image-wrapper {
  width: 33%;
  height: 100%;
  background-color: #707070;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.secondary-image {
  width: 100%;
  height: 50%;
  // background-image: url('../images/CurbNTurf_2.jpg');
  // background-position: 50%;
  // background-size: cover;
  flex: 0 auto;
  overflow: hidden;
}

.search-input {
  color: #fff;
  text-align: left;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid #fff;
  border-radius: 12px;
  padding: 24px;
  font-size: 16px;
  line-height: 1;
}

.search-input:focus {
  color: #454545;
  background-color: #fff;
  font-size: 16px;
}

.search-button {
  width: 32px;
  height: 32px;
  background-color: rgba(0, 0, 0, 0);
  background-image: url('/img/wf/search-icon-white.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0;
  font-size: 14px;
  position: absolute;
  top: 17%;
  bottom: 0%;
  left: auto;
  right: 6px;
}

.hero {
  position: relative;
  overflow: hidden;
}

.hero-search {
  width: 760px;
  color: #fff;
  align-self: center;
  margin-bottom: 24px;
  padding-top: 0;
  position: relative;
}

.content-center {
  text-align: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.content-center.grow {
  align-self: stretch;
  padding-top: 24px;
}

.content-center.affiliate-white {
  padding-top: 24px;
  display: none;
}

// .button {
//   width: 100%;
//   max-width: 260px;
//   min-width: 96px;
//   color: #fff;
//   text-align: center;
//   letter-spacing: 1.5px;
//   text-indent: 2px;
//   background-color: rgba(0, 0, 0, 0);
//   border: 2px solid #fff;
//   border-radius: 36px;
//   padding: 18px 24px;
//   font-family: rift-soft, sans-serif;
//   font-size: 18px;
//   font-weight: 600;
//   line-height: 1;
//   display: inline-block;
//   position: relative;
// }

// .button:hover {
//   color: #454545;
//   background-color: #fff;
//   font-weight: 700;
//   transform: translate(0);
// }

.button.trinidad {
  width: 100%;
  max-width: 480px;
  color: var(--primary);
  letter-spacing: 1.5px;
  text-transform: uppercase;
  transform-style: preserve-3d;
  border-width: 2px;
  border-color: var(--primary);
  border-radius: 36px;
  align-self: center;
  margin-top: 0;
  margin-left: 0;
  padding: 18px 24px;
  font-family: rift-soft, sans-serif;
  line-height: 1;
  transition: transform 0.3s, background-color 0.3s;
  position: static;
}

.button.trinidad:hover {
  color: #fff;
  background-color: var(--primary);
  transform: translate3d(0, -6px, 12px);
}

.button.trinidad.video {
  width: 360px;
}

.button.trinidad.center {
  transform-style: preserve-3d;
  font-size: 16px;
}

.button.trinidad.center:hover {
  transform: translate3d(0, -3px, 12px);
}

.button.form {
  margin-top: 36px;
}

.button.form:hover {
  transform: translate(0, -3px);
}

.button.nandor {
  width: 320px;
  max-width: none;
  min-width: auto;
  transform-style: preserve-3d;
  transition: transform 0.3s;
}

.button.nandor:hover {
  transform-origin: 50%;
  transform: translate3d(0, -3px, 12px);
}

.trinidad {
  color: var(--primary);
  margin-bottom: 10px;
}

.trinidad.center {
  margin-bottom: 12px;
}

.trinidad.nospacing {
  margin: 0;
}

.trinidad.lh-pad {
  flex: none;
  margin-bottom: 0;
  padding-left: 6px;
}

.large-device {
  width: 100vw;
  min-height: 50vw;
  background-image: url('/img/wf/airstream-inc-lzD1i1Rfbtw-unsplash.jpg');
  background-position: 50% 90%;
  background-size: cover;
}

.overlay {
  min-height: 100vh;
  min-width: auto;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.overlay.white {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
}

.overlay.modal {
  z-index: 2003;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.small-device-copy {
  min-height: 100vh;
  min-width: auto;
  background-image: url('/img/wf/willian-justen-de-vasconcellos-502743-unsplash-4k.jpg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.device-content-width-wrapper {
  width: 70vw;
}

.small-device {
  display: none;
}

.nandor-bg {
  color: #fff;
  background-color: #465d50;
}

.nandor-bg.grow {
  flex: 1;
}

.n-oramg {
  color: var(--primary);
}

.center {
  text-align: center;
  flex: none;
  margin-top: 0;
  margin-bottom: 0;
}

.center.m-18px {
  margin-top: 18px;
}

.listing-content-wrapper {
  width: 100%;
  max-width: 1440px;
  flex: 1;
  margin-left: auto;
  margin-right: auto;
  padding: 0 12px;
  display: flex;
}

.wizard-h3 {
  margin-bottom: 0;
}

.wizard-h3._18px {
  padding-top: 18px;
}

.rv-icon {
  width: 100%;
}

.rv-inner-wrapper {
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-left: 2%;
  margin-right: 2%;
  display: flex;
  position: relative;
}

.rv-grid {
  grid-column-gap: 0;
  grid-row-gap: 6px;
  color: #454545;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.rv-grid.garage {
  padding-top: 18px;
  padding-bottom: 18px;
}

.fine-print-trinidad {
  color: var(--primary);
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2em;
}

.fine-print-trinidad.arces {
  margin-left: 6px;
  padding-top: 4px;
}

.fine-print-trinidad.right-align {
  text-align: right;
}

.icon-bullet {
  width: 18px;
  flex: none;
  margin-top: 3px;
  margin-right: 6px;
  padding-right: 0;
}

.icon-inner-wrapper {
  width: auto;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0%;
  margin-right: 0%;
  display: flex;
}

.icon-inner-wrapper.with-dropdown {
  cursor: pointer;
  justify-content: space-between;
  position: relative;
}

.rift-soft-copy {
  letter-spacing: 1.5px;
  flex: none;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
  line-height: 1vh;
}

.icon-wrapper {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.listing-icon-grid {
  grid-column-gap: 12px;
  grid-row-gap: 6px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: start;
  display: grid;
}

.linsting-info-grid {
  grid-column-gap: 0;
  grid-row-gap: 0;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.div-block-33 {
  display: none;
}

.datepicker-button {
  width: 60%;
  text-align: center;
  justify-content: center;
  align-self: center;
  align-items: center;
  display: flex;
}

.booking-bottom-section {
  display: none;
}

.title-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

.text-span-9 {
  color: var(--primary);
}

.button-wrapper-grid {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  padding-top: 18px;
  display: grid;
}

.button-wrapper-grid.filter {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-bottom: 12px;
}

.button-wrapper-grid.listing {
  margin-top: 18px;
  margin-bottom: 18px;
}

.button-wrapper-grid.a-la-carte {
  padding-top: 0;
}

.button-wrapper-grid.categories {
  margin-bottom: 18px;
  padding-top: 0;
}

.details-text {
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 1.3em;
}

._3-up-grid {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  border: 1px #000;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  align-items: center;
  margin-bottom: 12px;
  display: grid;
}

._3-up-grid.filter {
  grid-template-rows: auto;
}

._3-up-grid.garage {
  grid-template-rows: auto;
  padding-top: 6px;
  padding-bottom: 18px;
}

._3-up-grid.transaction {
  grid-template-columns: 0.25fr 1.5fr 0.75fr;
}

._3-up-grid.categories {
  margin-bottom: 0;
}

._2-up-grid {
  grid-column-gap: 18px;
  grid-row-gap: 12px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-bottom: 9px;
  display: grid;
}

.location-inner {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.expanded {
  transform: rotate(-90deg);
}

.contact-host {
  max-width: 20%;
  min-width: 144px;
  padding-top: 12px;
}

.share-icon-wrapper {
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: -9px;
  padding-top: 12px;
  padding-left: 6px;
  display: flex;
}

.rating-inner {
  justify-content: center;
  align-items: center;
  display: flex;
}

.rating-icon {
  width: 16px;
  text-align: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  margin-top: 5px;
  font-size: 12px;
  display: flex;
}

.rating-icon.review {
  width: 32px;
}

.price-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

.rating-avg {
  padding-top: 0;
  padding-bottom: 3px;
  font-family: rift-soft, sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.rating-total {
  letter-spacing: 1.5px;
  padding-bottom: 1px;
  padding-left: 3px;
  font-family: rift-soft, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.review-overall {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.heading-13 {
  margin-bottom: 5px;
}

.copy-regular {
  color: #484848;
  margin-right: 5px;
  font-size: 17px;
  line-height: 24px;
  display: inline-block;
}

.review-rating-wrapper {
  border: 1px #000;
  justify-content: center;
  align-items: center;
  display: flex;
}

.reviews-grid {
  grid-column-gap: 24px;
  grid-row-gap: 0;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr 1fr;
}

.reviews-grid.reviews {
  margin-top: 18px;
}

.icon-helpful {
  width: 18px;
  flex: none;
  padding-right: 6px;
}

.icon-verify {
  width: 34px;
  flex: none;
  justify-content: flex-start;
  align-self: auto;
  align-items: flex-start;
  padding-top: 0;
  padding-right: 10px;
  display: block;
}

.location-join-date {
  margin-bottom: 12px;
}

._6px-lp {
  padding-right: 9px;
}

.report-wrapper {
  justify-content: center;
  align-items: center;
  padding-right: 12px;
  display: flex;
}

.footer-inner {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template:
    '.'
    '.'
    / 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: block;
}

.footer-wrapper {
  border: 1px #000;
  border-top: 1px solid #d8d6d6;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  padding-top: 36px;
  padding-bottom: 36px;
  display: flex;
}

.footer-wrapper.dark {
  color: #fff;
  background-color: #465d50;
}

.footer-grid {
  grid-column-gap: 36px;
  grid-row-gap: 0;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.icon-follow-us {
  width: 18px;
  margin-right: 12px;
  padding-right: 0;
}

.icon-follow-us:hover {
  width: 18px;
  padding-right: 0;
}

.follow-us-wrapper {
  display: flex;
}

.h4-footer {
  color: #707070;
}

.h4-footer.alt {
  color: #fff;
}

.h4-footer.white {
  color: #fff;
  text-align: left;
}

.icon-copyright {
  width: 12px;
  margin-top: 1px;
  margin-right: 4px;
}

.text-copyright {
  color: #707070;
  font-size: 12px;
}

.text-copyright.alt,
.text-copyright.white {
  color: #fff;
}

.copy-wrapper {
  align-items: center;
  padding-right: 40px;
  display: flex;
}

.datepicker-inner {
  border: 1px solid #d8d6d6;
  border-radius: 9px;
  justify-content: center;
  align-self: stretch;
  display: flex;
  position: relative;
}

.navbar {
  height: 100%;
  background-color: #fff;
}

.navbar.guest {
  background-color: rgba(0, 0, 0, 0);
}

.navigation-link {
  height: 72px;
  color: #454545;
  flex: none;
  padding-top: 25px;
}

.navigation-link:hover {
  border-bottom: 4px solid var(--primary);
}

.navigation-link.w--current {
  color: #454545;
  flex: none;
  text-decoration: none;
}

.small-device-menu {
  display: flex;
}

.datepicker-text {
  color: #707070;
}

// keep for now
.pricing-section {
  display: flex;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #d8d6d6;
}

// keep for now
.line-item {
  color: #707070;
}

// keep for now
.line-item-price {
  color: #707070;
}

// keep for now
.total {
  font-weight: 600;
}

.filter-outer {
  height: 48px;
  border-top: 1px #d8d6d6;
  border-bottom: 1px solid #d8d6d6;
  display: block;
}

.filter-outer.small {
  display: none;
}

.filter-wrapper {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.filter-form {
  max-width: 1140px;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5px;
  display: flex;
  position: static;
}

.search-background {
  width: 100vw;
  height: 100%;
  flex: 0 auto;
  display: flex;
}

.body {
  height: 100%;
}

.full-view-wrapper {
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.results-wrapper {
  flex: 1;
  display: flex;
  position: relative;
}

.map-wrapper {
  flex: 25 0 auto;
}

.listing-card-private {
  width: 100%;
  height: 100%;
  max-height: 300px;
  max-width: 300px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.listing-info-wrapper {
  height: 100%;
  max-height: 84px;
  max-width: 300px;
  border: 1px solid #454545;
  border-top-style: none;
  border-radius: 0;
  padding: 12px;
}

.listing-info-wrapper.results {
  max-height: 106px;
  border-radius: 0;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.text-span-10 {
  color: var(--primary);
}

.listing-name {
  margin-bottom: 0;
  font-size: 20px;
}

.listing-image-wrapper {
  height: 100%;
  max-height: 216px;
  max-width: 300px;
  background-image: url('/img/wf/CurbNTurf_1.jpg');
  background-position: 50%;
  background-size: cover;
  border: 1px solid #454545;
  border-bottom-style: none;
  border-radius: 0;
  position: relative;
}

.card-rift-soft {
  letter-spacing: 1.5px;
  margin-top: -3px;
  font-family: rift-soft, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2em;
}

.card-rift-soft.white {
  text-align: left;
  margin-top: -3px;
}

.card-rift-soft.trinidad {
  margin-bottom: 6px;
}

.card-price {
  color: var(--primary);
  letter-spacing: 1.5px;
  font-family: rift-soft, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2em;
}

.card-price.white {
  color: #fff;
  font-weight: 600;
}

.icon-fav {
  width: 18px;
  margin-top: 4px;
  padding-top: 0;
  padding-bottom: 0;
}

.listing-title-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.price-rating-wrapper {
  justify-content: space-between;
  align-items: center;
  margin-top: -3px;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

.small-listing-card-boondock,
.small-listing-card-private {
  width: 220px;
  height: 220px;
}

.small-listing-card-private .listing-card,
.small-listing-card-boondock .listing-card {
  width: 100%;
  height: 100%;
  max-height: 300px;
  max-width: 300px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.selected.small-listing-card-private .listing-information-wrapper {
  padding: 12px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  background-color: #465d50;
}

.selected.small-listing-card-boondock .listing-information-wrapper {
  padding: 12px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  background-color: #e3530d;
}

.selected.small-listing-card-boondock .listing-image {
  height: 100%;
  max-height: 216px;
  max-width: 300px;
  border-style: solid solid none;
  border-width: 2px;
  border-color: #465d50;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  background-position: 50% 50%;
  background-size: cover;
}

.selected.small-listing-card-private,
.selected.small-listing-card-boondock {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 220px;
  height: 220px;
  margin-right: 18px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.selected.private-listing-card .listing-image {
  height: 100%;
  max-height: 216px;
  max-width: 300px;
  border-style: solid solid none;
  border-width: 2px;
  border-color: #e3530d;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  background-position: 50% 50%;
  background-size: cover;
}

.map {
  width: 100%;
  height: 100%;
  border-top: 1px #000;
}

.private-listing-card {
  width: 100%;
  height: 100%;
  max-height: 212px;
  max-width: none;
  justify-content: space-between;
  display: flex;
}

.listing-image {
  width: 40%;
  max-height: 212px;
  max-width: none;
  background-color: #d8d6d6;
  // background-image: url('../images/CurbNTurf_1.jpg');
  background-position: 50%;
  background-size: cover;
  border: 1px solid #454545;
  border-right-style: none;
  flex: none;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.listing-information-wrapper {
  width: 60%;
  max-height: 212px;
  max-width: none;
  border: 1px solid #454545;
  border-left-style: none;
  flex-direction: column;
  flex: none;
  display: flex;
}

.amenities-inner {
  max-height: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.amenities-inner.filter {
  padding-top: 6px;
  padding-bottom: 6px;
}

.listing-name-wrapper {
  height: 25%;
  padding: 12px 12px 0;
}

.listing-price-wrapper {
  height: 25%;
  padding: 12px;
}

.text-span-11 {
  color: var(--primary);
}

.icon-save {
  width: 18px;
  height: 18px;
  flex: 1;
  margin-bottom: 0;
  padding-top: 4px;
  position: relative;
}

.save-icon-wrapper {
  width: 36px;
  background-color: rgba(69, 69, 69, 0.5);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 6px;
  padding-left: 4px;
  padding-right: 6px;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 12px;
  left: 0;
  right: auto;
}

.save-icon-wrapper.listing {
  bottom: 72px;
}

.listing-results-large {
  height: 100%;
  max-width: 916px;
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  flex: 1 0 auto;
  grid-template-rows: 212px;
  grid-template-columns: auto;
  grid-auto-rows: 212px;
  grid-auto-columns: 1fr;
  padding: 18px;
  display: grid;
}

.listing-name-h3 {
  white-space: nowrap;
  margin-bottom: 0;
  overflow: hidden;
}

.listing-name-h3.white {
  text-align: left;
}

.listing-location {
  letter-spacing: 1.5px;
  font-family: rift-soft, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2em;
}

.listing-location.white {
  text-align: left;
}

.location-price {
  color: var(--primary);
  letter-spacing: 1.5px;
  margin-top: 2px;
  font-family: rift-soft, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2em;
}

.selected .location-price,
.location-price.white {
  color: #fff;
}

.listing-price-inner {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.amenities-pill {
  height: 18px;
  background-color: #454545;
  border-radius: 50vh;
  justify-content: center;
  align-items: center;
  margin: 4px;
  padding: 12px;
  display: flex;
}

.amenities-pill-copy {
  color: #fff;
  letter-spacing: 1.5px;
  font-family: rift-soft, sans-serif;
  font-size: 14px;
  font-weight: 600;
  display: block;
}

.amenities-wrapper {
  height: 50%;
  padding: 12px 12px 0;
}

.boondock-listing-card,
.selected.private-listing-card {
  width: 100%;
  height: 100%;
  max-height: 212px;
  max-width: none;
  justify-content: space-between;
  display: flex;
}

.selected.private-listing-card .listing-image {
  position: relative;
  display: flex;
  width: 40%;
  max-height: 212px;
  max-width: none;
  justify-content: space-between;
  border-style: solid none solid solid;
  border-width: 2px;
  border-color: #e3530d;
  border-top-left-radius: 9px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 9px;
  background-color: #d8d6d6;
  background-position: 50% 50%;
  background-size: cover;
}

.selected.boondock-listing-card .listing-image {
  border-style: solid;
  border-color: #465d50;
}

.selected.private-listing-card .listing-information-wrapper {
  width: 60%;
  max-height: 212px;
  max-width: none;
  border-style: solid solid solid none;
  border-width: 2px;
  border-color: #e3530d;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
  background-color: #e3530d;
}

.selected.boondock-listing-card .listing-information-wrapper {
  border-style: solid;
  border-color: #465d50;
  background-color: #465d50;
}

.selected .listing-information-wrapper,
.selected .listing-information-wrapper h3,
.selected .listing-information-wrapper h3 span.trinidad {
  color: #fff;
}

.selected .amenities-pill {
  display: flex;
  height: 18px;
  margin: 4px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 50vh;
  background-color: #fff;
}

.selected .amenities-pill-copy {
  color: #e3530d;
  letter-spacing: 1.5px;
  font-family: rift-soft, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.selected.boondock-listing-card .amenities-pill-copy {
  color: #465d50;
}

.selected.boondock-listing-card {
  width: 100%;
  height: 100%;
  max-height: 212px;
  max-width: none;
  justify-content: space-between;
  display: flex;
}

.r-chevron-wrapper {
  width: 36px;
  height: 210px;
  background-color: rgba(69, 69, 69, 0.5);
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.l-chevron-wrapper {
  width: 36px;
  height: 210px;
  background-color: rgba(69, 69, 69, 0.5);
  justify-content: space-between;
  display: flex;
  position: relative;
}

.icon-r-chevron {
  width: 18px;
  position: absolute;
  top: 96px;
  bottom: 0%;
  left: auto;
  right: 15%;
}

.icon-l-chevron {
  width: 18px;
  position: absolute;
  top: 96px;
  bottom: 0%;
  left: 15%;
  right: auto;
  transform: rotate(0);
}

.photo-wrapper {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  padding-top: 18px;
  display: grid;
}

.filter-dates-wrapper {
  z-index: 5;
  width: 650px;
  background-color: #fff;
  border: 1px solid #d8d6d6;
  border-top-style: none;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  padding: 24px;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.filter-header-wrapper {
  padding-bottom: 0;
}

.dates-selected {
  color: var(--primary);
  letter-spacing: 1.5px;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
}

.info-inner {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding-left: 0;
  padding-right: 0;
  display: flex;
}

.bottom-button-wrapper {
  justify-content: flex-end;
  margin-top: 12px;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

.bottom-button-wrapper.small-device-footer {
  z-index: 999;
  background-color: #fff;
  border-top: 1px solid #d8d6d6;
}

.bottom-button-wrapper.listing {
  margin-top: 18px;
}

.months-inner-wrapper {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-direction: row;
  flex: 0 auto;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 0;
  display: flex;
}

.price-range {
  color: var(--primary);
  letter-spacing: 1.5px;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
}

.filter-price-wrapper {
  z-index: 5;
  width: 450px;
  background-color: #fff;
  border: 1px solid #d8d6d6;
  border-top-style: none;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
  right: 0;
}

.listing-name-outer-wrapper {
  padding-right: 12px;
}

.static-slider-wrapper {
  text-align: left;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
  display: flex;
}

.filter-distance-wrapper {
  z-index: 5;
  width: 450px;
  height: 400px;
  background-color: #fff;
  border-bottom: 1px solid #d8d6d6;
  border-left: 1px solid #d8d6d6;
  border-right: 1px solid #d8d6d6;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.distance-search {
  height: 48px;
  border: 1px solid #454545;
  border-radius: 9px;
  position: relative;
}

.icon-rv-camper {
  z-index: 6;
  width: 48px;
  margin-right: 6px;
  position: static;
}

.current-location-wrapper {
  justify-content: space-between;
  display: flex;
}

.current-location-wrapper.small-device {
  width: 420px;
  margin-bottom: 18px;
}

.use-current-location {
  letter-spacing: 1.5px;
  padding-left: 9px;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
}

.use-current-location-inner {
  align-items: center;
  display: flex;
}

.current-location-inner {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.payette {
  color: var(--primary);
  letter-spacing: 1.5px;
  padding-right: 6px;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
}

.icon-current {
  width: 12px;
}

.html-embed-5 {
  margin-left: 0;
  padding-top: 6px;
  padding-left: 0;
}

.distance {
  color: var(--primary);
  letter-spacing: 1.5px;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
}

.filter-horizontal-line {
  border-bottom: 1px solid #d8d6d6;
}

.filter-my-rv-wrapper {
  width: 700px;
  background-color: #fff;
  border: 1px solid #d8d6d6;
  border-top-style: none;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
  right: 0;
}

.rv-setup {
  letter-spacing: 1.5px;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
}

.filter-header {
  text-align: center;
  letter-spacing: 1.5px;
  font-family: rift-soft, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.profile-wrapper {
  width: 100%;
  height: 48px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 18px;
  padding-left: 0;
  display: flex;
}

.profile-wrapper.small-device {
  width: 420px;
  margin-bottom: 0;
}

.select-your-profile,
.amenities {
  letter-spacing: 1.5px;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
}

.amenities.small-device {
  display: block;
}

.filter-amenities-wrapper {
  width: 650px;
  background-color: #fff;
  border: 1px solid #d8d6d6;
  border-top-style: none;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 24px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
  right: 0;
}

.activities-inner {
  background-color: #fff;
  border: 1px solid #454545;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
}

.activities-inner.filter {
  background-color: #fff;
  border: 1px solid #454545;
  border-top-style: none;
}

.filter-activities-wrapper {
  width: 650px;
  background-color: #fff;
  border: 1px solid #d8d6d6;
  border-top-style: none;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  flex-direction: column;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  padding: 24px;
  display: block;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.activities {
  letter-spacing: 1.5px;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
}

.small-device-header {
  z-index: 999;
  height: 60px;
  background-color: #fff;
  border: 1px solid #d8d6d6;
  padding: 12px 36px;
  position: fixed;
  top: 0;
  bottom: auto;
  left: 0;
  right: 0;
}

.dates-small {
  letter-spacing: 1.5px;
  padding-top: 3px;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
}

.calendar-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  display: flex;
}

.date-picker {
  text-align: left;
  margin-top: 12px;
  position: static;
}

.days-week {
  grid-column-gap: 0;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 36px 36px 36px 36px 36px 36px 36px;
  grid-auto-columns: 1fr;
  margin: 9px 12px 12px;
  display: grid;
}

.month {
  grid-column-gap: 0;
  grid-row-gap: 0;
  grid-template-rows: 36px 36px 36px 36px 36px 36px;
  grid-template-columns: 36px 36px 36px 36px 36px 36px 36px;
  grid-auto-columns: 36px;
  grid-auto-flow: row;
  justify-content: center;
  align-items: center;
  justify-items: center;
  display: grid;
}

.month-name {
  justify-content: center;
  align-items: center;
  display: flex;
}

.day-blank {
  width: 32px;
  height: 32px;
  align-self: center;
}

.small-device-footer {
  height: 60px;
  background-color: #fff;
  padding: 12px 36px;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.icon-previous {
  width: 18px;
  margin-left: 3px;
  padding-left: 0;
}

.icon-next {
  width: 18px;
  margin-right: 1px;
  padding-right: 0;
}

.previous-month-wrapper {
  flex-direction: column;
  flex: 0 auto;
  align-items: flex-start;
  margin-right: 0;
  display: flex;
}

.next-month-inner {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}

.small-device-filter-wrapper {
  margin-top: 72px;
  margin-bottom: 72px;
  padding-left: 36px;
  padding-right: 36px;
}

.icon-filter-slider {
  width: 320px;
}

.small-device-horizontal {
  border-top: 1px solid #d8d6d6;
  padding-bottom: 12px;
}

.icon-exit {
  width: 18px;
}

.small-device-inner {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
}

.width-wrapper {
  width: 420px;
}

._72-px-spacer {
  height: 72px;
}

.map-button {
  width: 72px;
  height: 36px;
  background-color: #fff;
  border: 1px solid #454545;
  border-left-style: none;
  border-top-right-radius: 50vh;
  border-bottom-right-radius: 50vh;
  align-items: center;
  margin-top: 36px;
  padding-top: 6px;
  padding-left: 6px;
  text-decoration: none;
  display: flex;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
  right: auto;
}

.icon-map {
  width: 18px;
}

.list-button {
  width: 72px;
  height: 36px;
  background-color: #fff;
  border: 1px solid #454545;
  border-left-style: none;
  border-top-right-radius: 50vh;
  border-bottom-right-radius: 50vh;
  justify-content: flex-start;
  align-items: center;
  margin-top: 36px;
  padding-top: 0;
  padding-left: 6px;
  text-decoration: none;
  display: flex;
  position: absolute;
}

.icon-list {
  width: 18px;
  margin-top: 4px;
  padding-top: 0;
}

.map-card-wrapper {
  justify-content: flex-start;
  padding-right: 0;
  display: flex;
}

.first-name-wrapper {
  flex: 1;
  padding-right: 24px;
}

.name-wrapper {
  justify-content: space-between;
  display: flex;
}

.text-field {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid #fff;
  border-radius: 12px;
  padding: 24px;
}

.text-field:focus {
  color: #454545;
  background-color: #fff;
}

.text-field.trinidad {
  border-color: var(--primary);
}

.error-message {
  background-color: red;
}

.phone-number-wrapper {
  flex: 1;
  padding-right: 24px;
}

.last-name-wrapper {
  flex: 1;
}

.city-state-zip-wrapper {
  justify-content: space-around;
  padding-left: 0;
  display: flex;
}

.state-inner {
  flex: 1;
  padding-right: 24px;
}

.city-inner {
  flex: 0 auto;
  padding-right: 24px;
}

.success-message {
  color: #fff;
  background-color: #05b150;
}

.image-8 {
  align-self: center;
  padding-top: 2%;
}

.email-wrapper {
  flex: 1;
}

.hero-background {
  background-image: url('/img/wf/sydney-angove-GJJGpecwvMs-unsplash-4k.jpg');
  background-position: 0 100%;
  background-size: cover;
}

.hero-background.barn {
  background-image: url('/img/wf/frances-gunn-39236-unsplash-4k.jpg');
}

.contact-wrapper {
  display: flex;
}

.field-label {
  // these are for the agritourism course
  // text-align: left;
  // letter-spacing: 1.5px;
  // margin-bottom: 3px;
  // font-family: rift-soft, sans-serif;
  // font-weight: 600;
  font-weight: 400;
}

.field-label.white.form {
  text-align: left;
}

.white-centered {
  color: #fff;
  text-align: center;
  text-transform: none;
  margin-bottom: 24px;
  line-height: 1em;
}

.vertical-center {
  flex-direction: column;
  display: flex;
}

.table-inner-wrapper {
  padding: 9px 9px 9px 0;
}

.table-title-inner {
  align-items: flex-end;
  padding: 9px 9px 9px 0;
  display: flex;
}

.affiliate-title {
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  display: flex;
}

.bullet-wrapper {
  margin-bottom: 0;
  display: flex;
}

.bullet {
  color: #fff;
  margin-bottom: 0;
}

.bullet.tundora {
  color: #454545;
}

.affiliate-wrapper {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: auto auto auto;
  grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.affiliate-rep-wrapper {
  padding-top: 29px;
}

.video {
  margin-bottom: 24px;
  padding-bottom: 0;
}

.icon-facebook {
  width: 24px;
}

.icon-twitter {
  width: 24px;
  margin-left: 9px;
}

.section-11 {
  background-color: #fff;
  padding-top: 36px;
  padding-bottom: 36px;
}

.hero-section {
  height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  position: relative;
}

.text-block-20 {
  white-space: nowrap;
  transform: translate(0, -32px) rotate(90deg);
}

.tagline-line {
  width: 72px;
  height: 2px;
  background-color: #fbd784;
  margin-right: 24px;
}

.tagline-line.trinidad {
  background-color: var(--primary);
  align-self: auto;
  margin-bottom: 0;
}

// keep for now
.content-grid.affiliate {
  grid-column-gap: 12px;
  grid-row-gap: 0px;
}

.nav-slider-links {
  width: 100px;
  flex-direction: column;
  align-items: flex-end;
  font-weight: 700;
  display: flex;
  position: relative;
}

.tagline {
  color: #fbd784;
  align-items: center;
  margin-bottom: 32px;
  display: flex;
}

.gradient-hero {
  width: 100%;
  height: 100vh;
  opacity: 0.6;
  background-image: linear-gradient(135deg, #465d50, rgba(0, 0, 0, 0));
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.link-to {
  letter-spacing: 1.5px;
  align-items: center;
  font-family: rift-soft, sans-serif;
  font-weight: 700;
  text-decoration: none;
  display: flex;
}

.link-to.hero {
  color: #fff;
  justify-content: center;
  text-decoration: none;
  transition: all 0.2s;
}

.link-to.hero:hover {
  color: #fbd784;
}

.link-to.hero.more {
  color: #fbd784;
  letter-spacing: 1.5px;
  margin-top: 18px;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
  text-decoration: none;
}

.link-to.hero.more:hover {
  transform: translate(2px);
}

.link-to.hero.more.trinidad {
  color: var(--primary);
  margin-top: 0;
  padding-bottom: 0;
  display: none;
}

.cnt-logo-wrapper {
  width: 72px;
}

.content-outer {
  width: 100%;
  height: auto;
  max-width: 550px;
  position: static;
}

.content-outer.tundora {
  color: #454545;
  position: static;
}

.count {
  z-index: 0;
  opacity: 0.1;
  color: #fff;
  font-size: 100px;
  font-weight: 700;
  line-height: 100%;
  display: block;
  position: absolute;
  top: -60%;
  bottom: auto;
  left: -9%;
  right: auto;
}

.count._2 {
  top: -10%;
  bottom: auto;
  left: -10%;
  right: auto;
}

.count._3 {
  top: -15%;
  bottom: auto;
  left: -10%;
  right: auto;
}

.count._4 {
  top: -15%;
  left: -10%;
}

.nav-link-hero.nav {
  color: #fff;
  align-self: center;
  margin-top: 0;
  padding: 8px 24px;
  text-decoration: none;
  transition: all 0.2s;
  display: block;
}

.nav-link-hero.nav:hover {
  color: #fbd784;
  transform: translate(0, -2px);
}

.hero-nav-wrapper {
  z-index: 100;
  padding-top: 40px;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.content-hero {
  width: 100%;
  max-width: 880px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.content-hero.learn {
  z-index: 0;
  max-width: 1080px;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 120px;
  padding-left: 120px;
  padding-right: 120px;
  position: static;
}

.content-hero.learn.host {
  padding-top: 220px;
}

.nav-link-wrapper {
  color: #fff;
  align-self: flex-start;
  font-weight: 700;
  display: flex;
}

.heading-tagline {
  color: #fbd784;
}

.heading-tagline.trinidad {
  color: var(--primary);
  letter-spacing: 0;
  font-family: proxima-nova, sans-serif;
}

.indicator {
  width: 3px;
  height: 48px;
  background-color: #fff;
}

.social-links {
  z-index: 50;
  width: 100px;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  display: none;
  position: -webkit-sticky;
  position: sticky;
  top: auto;
  bottom: auto;
  left: 0%;
  right: auto;
}

.indicator-line {
  background-color: rgba(255, 255, 255, 0.4);
}

.nav-slider {
  z-index: 50;
  display: none;
  position: -webkit-sticky;
  position: sticky;
  top: auto;
  bottom: auto;
  left: auto;
  right: 0%;
}

.slider-link {
  color: #fff;
  padding: 8px 24px 8px 40px;
  transition: all 0.2s;
}

.slider-link:hover {
  color: #fbd784;
  transform: translate(5px);
}

.content-grid {
  width: 100%;
  grid-column-gap: 72px;
  grid-row-gap: 36px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-top: 10px;
  margin-bottom: 10px;
  display: grid;
}

.content-grid.fit {
  margin-bottom: 180px;
}

.content-grid.estimator {
  margin-bottom: 80px;
}

.content-grid.affiliate {
  grid-column-gap: 12px;
  grid-row-gap: 0px;
}

.icon-scroll {
  width: 16px;
  height: 24px;
  margin-left: 16px;
}

.icon-scroll.more {
  transform: rotate(-90deg);
}

.hg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.content-wrapper {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 80px;
  padding-right: 80px;
  display: flex;
}

.content-wrapper.content {
  max-width: 1440px;
  margin-bottom: 280px;
  position: static;
}

.content-wrapper.content.short {
  margin-top: 60px;
  margin-bottom: 140px;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.content-wrapper.content.short.animate,
.content-wrapper.content.short.video {
  margin-top: 40px;
  margin-bottom: 0;
}

.content-wrapper.nav {
  justify-content: space-between;
  align-items: flex-start;
}

.content-wrapper.hero {
  z-index: 50;
  max-width: 1920px;
  padding-top: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 20%;
}

.vg {
  z-index: 50;
  position: absolute;
  top: 60%;
  bottom: 0;
  left: 0;
  right: 0;
}

.mg {
  z-index: 0;
  position: absolute;
  top: 30%;
  bottom: 0;
  left: 0;
  right: 0;
}

.breakpoint-wrapper {
  max-width: 1920px;
  background-color: rgba(0, 0, 0, 0);
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.breakpoint-wrapper.five {
  width: 100%;
  height: 100%;
  min-height: 480px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  padding-top: 0;
  display: grid;
  position: relative;
}

.breakpoint-wrapper.cnt-app {
  min-height: 480px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-top: 40px;
  display: grid;
  position: relative;
}

.icon {
  width: 24px;
  height: 24px;
}

.icon.social {
  margin-bottom: 16px;
  margin-right: 0;
  transform: rotate(90deg);
}

.content-section {
  z-index: 50;
  padding-top: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.content-section.nandor-background {
  display: block;
  position: relative;
  top: 0;
}

.content-section.regular {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  display: block;
  position: relative;
}

.grid-photo {
  width: 100%;
  height: 680px;
  max-width: 100%;
  background-image: url('/img/wf/AdobeStock_296386260_1080x691.jpg');
  background-position: 100%;
  background-size: cover;
}

.grid-photo.traveled {
  background-image: url('/img/wf/tim-gouw-JasperNationalPark-Tim-Gouw.jpg');
  background-position: 50%;
}

.grid-photo.planning {
  background-image: url('/img/wf/airstream-inc-pcKqDMeWbD8-unsplash.jpg');
}

.grid-photo.boondocking {
  background-image: url('/img/wf/aurelien-designatic-782561-unsplash_4k.jpg');
  background-position: 50%;
}

.grid-photo.adventure {
  background-image: url('/img/wf/AdobeStock_282260949_1920x1280.jpg');
  background-position: 25%;
}

.grid-photo.best {
  background-image: url('/img/wf/ludovic-fremondiere-386791-unsplash_1920x1439.jpg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.grid-photo.works {
  background-image: url('/img/wf/joel-holland-RwxJtL-WshE-unsplash_CNT.jpg');
  background-position: 55%;
}

.grid-photo.app {
  background-image: url('/img/wf/CurbNTurf-App-Phone-mockup-4k.png');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto 800px;
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

.grid-photo.benefits {
  background-image: url('/img/wf/hari-nandakumar-1440558-unsplash-4k.jpg');
}

.grid-photo.problem-more {
  background-image: url('/img/wf/rv-talk-Fk5cBS4BUvQ-unsplash.jpg');
  background-position: 65%;
}

.grid-photo.boondocking-more {
  background-image: url('/img/wf/chris-holder-658988-unsplash.jpg');
}

.grid-photo.started {
  background-image: url('/img/wf/AdobeStock_306216284_1920x1080.jpg');
  background-position: 0;
}

.grid-photo.details {
  background-image: url('/img/wf/kent-rebman-Hau9TnHS130-unsplash.jpg');
  background-position: 80%;
  background-size: cover;
}

.grid-photo.facts {
  background-image: url('/img/wf/AdobeStock_207612528_1920x1280.jpg');
  background-position: 25%;
  background-size: cover;
}

.grid-photo.affiliate {
  background-image: url('/img/wf/jessica-rockowitz-1145859-unsplash_1920x1478.jpg');
}

.grid-photo.success {
  background-image: url('/img/wf/airstream-inc-pcKqDMeWbD8-unsplash.jpg');
}

.grid-photo.money {
  background-image: url('/img/wf/AdobeStock_164016813_1920x1280.jpg');
  background-position: 50%;
}

.grid-photo.triptych {
  background-image: url('/img/wf/Phone-TripTych-p-800.png');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: scroll;
  position: relative;
}

.grid-photo.parking {
  background-image: url('/img/wf/MyrtleBeach_OverCrowded.jpg');
  background-position: 67%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
}

.grid-photo.program {
  background-image: url('/img/wf/AdobeStock_282260949_1920x1280.jpg');
  background-position: 50%;
}

.text-block-21 {
  text-decoration: none;
}

.h2-head {
  margin-bottom: 18px;
}

.h2-head.center {
  margin-bottom: 0;
}

.login-wrapper {
  justify-content: flex-start;
  align-items: center;
  margin-right: 0;
  display: flex;
}

.icon-login {
  margin-bottom: -6px;
  margin-right: 8px;
}

.gradient-section {
  z-index: 50;
  height: 230px;
  color: #465d50;
  background-image: linear-gradient(to top, #465d50 90%, rgba(0, 0, 0, 0));
  position: relative;
}

.content-inner-wrapper {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex: 0 auto;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  justify-content: stretch;
  padding-bottom: 10px;
  display: grid;
}

.content-inner-wrapper.regular {
  height: 100%;
  padding-bottom: 0;
}

.content-inner-wrapper.large {
  border-radius: 20px;
  padding-top: 120px;
  position: relative;
}

.content-hero-inner {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.icon-hero-bullet {
  width: 9px;
  flex: none;
  margin-top: 3px;
  margin-right: 12px;
}

.hero-paragraph {
  margin-top: 18px;
  margin-bottom: 0;
}

.navajo-white-bold {
  color: #fbd784;
  text-transform: none;
  font-weight: 700;
}

.start-hosting {
  width: 100%;
  height: 680px;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.content {
  z-index: 10;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 80px;
  display: flex;
  position: relative;
}

.right {
  width: 50%;
  height: 100%;
  min-width: 350px;
  background-color: #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.section-12 {
  width: 100%;
  height: 100%;
  color: #fff;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.host-with-us {
  width: 50%;
  height: 100%;
  max-height: 680px;
  min-width: 320px;
  align-items: center;
  display: block;
  position: relative;
  overflow: hidden;
}

.first-five {
  width: 50%;
  height: 100%;
  max-height: 680px;
  min-width: 320px;
  position: relative;
  overflow: hidden;
}

.color-overlay {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

.start-hosting-image {
  width: 100%;
  height: 100%;
  background-image: url('/img/wf/AdobeStock_225247819_1080x720.jpg');
  background-position: 50%;
  background-size: cover;
}

.start-hosting-image.first-five {
  background-image: url('/img/wf/frances-gunn-39236-unsplash-4k.jpg');
  background-position: 0;
  background-size: cover;
}

.footer-dark-wrapper {
  background-color: #465d50;
  border-top: 0.5px #d8d6d6;
  justify-content: center;
  align-items: center;
  padding-top: 36px;
  padding-bottom: 36px;
  display: flex;
}

.copy-white {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
}

.copy-white:hover {
  font-weight: 700;
}

.icon-follow-us-white {
  width: 18px;
  margin-right: 12px;
}

.icon-copywrite-white {
  width: 12px;
  margin-top: 1px;
  margin-right: 4px;
}

.button-large-wrapper {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1.5fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 12px;
  display: grid;
}

.button-large-wrapper.video {
  text-align: left;
  flex-direction: column;
  grid-template-columns: 2.5fr;
  align-content: stretch;
  justify-content: center;
  align-items: center;
  justify-items: stretch;
  display: flex;
}

.button-large-wrapper.no-margin {
  margin-top: 0;
  margin-bottom: 0;
}

.button-large-wrapper.tp-36 {
  padding-top: 36px;
}

.secondary-hero-wrapper {
  height: 820px;
  background-image: url('/img/wf/willian-justen-de-vasconcellos-502743-unsplash-4k.jpg');
  background-position: 50% 100%;
  background-size: cover;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0;
  display: flex;
  position: static;
}

.secondary-hero-wrapper.host {
  background-image: url('/img/wf/benjamin-davies-260884-unsplash-4k.jpg');
}

.secondary-hero-wrapper.host-more {
  background-image: url('/img/wf/frances-gunn-39236-unsplash-4k.jpg');
  background-position: 0;
}

.secondary-hero-wrapper.affiliate {
  background-image: url('/img/wf/sydney-angove-GJJGpecwvMs-unsplash-4k.jpg');
}

.hero-inner {
  width: 100%;
  height: 28vw;
  max-width: 1920px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
}

.tertiary-image {
  width: 100%;
  height: 50%;
  // background-image: url('../images/CurbNTurf_3.jpg');
  background-position: 50%;
  background-size: cover;
}

.nav-bar-inner {
  width: 100%;
  height: 100%;
  max-width: 1920px;
  justify-content: space-between;
  display: flex;
}

.image-sky,
.image-house {
  display: block;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: auto;
  right: auto;
}

.image-balloon {
  position: absolute;
  top: auto;
  bottom: 0%;
  left: auto;
  right: auto;
}

.promo-copy-wrapper {
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: auto;
  left: auto;
  right: 0;
}

.promo-copy-wrapper.video {
  margin-bottom: 0;
  position: static;
}

.promo-copy-wrapper.left {
  top: 0;
  bottom: auto;
  left: 0;
  right: auto;
}

.promo-copy-wrapper.bottom {
  text-align: center;
  position: static;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
}

.learn-more {
  color: #fbd784;
  letter-spacing: 1.5px;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 18px;
  font-family: rift-soft, sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  transition: opacity 0.4s;
  display: flex;
}

.learn-more:hover {
  transform: translate(12px);
}

.image-blue-sky,
.image-bluehouse,
.image-bluerv {
  position: absolute;
}

.description-type {
  color: #454545;
  text-align: center;
  letter-spacing: 1.5px;
  padding-bottom: 11px;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
  line-height: 1em;
}

.app-text-area {
  min-height: 120px;
  border: 1px solid var(--primary);
  border-radius: 12px;
}

.app-class-a {
  padding-top: 180px;
}

.video-wrapper {
  width: 100%;
  height: 100%;
}

.dark-overlay {
  width: 100%;
  height: 820px;
  max-width: 1920px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.app-camper {
  padding-top: 160px;
}

.trinidad-bold {
  color: var(--primary);
  font-weight: 700;
}

.content-text-inner {
  position: relative;
}

.three-column-grid {
  width: 100%;
  grid-column-gap: 48px;
  grid-row-gap: 180px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  margin-bottom: 180px;
  display: grid;
}

.illustration {
  width: 100%;
}

.estimator-wrapper {
  width: 100%;
  padding-top: 0;
}

.contact-us {
  color: #fff;
  text-decoration: none;
}

.scouting {
  padding-bottom: 80px;
}

.faq-text-details {
  text-align: center;
  font-size: 18px;
  line-height: 24px;
}

.faq-question-wrap {
  margin-bottom: 36px;
}

.animation {
  width: 60px;
  height: 60px;
}

.rift-soft-title {
  color: #454545;
  letter-spacing: 1.5px;
  justify-content: flex-start;
  align-items: center;
  margin-right: 6px;
  font-family: rift-soft, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2em;
  display: flex;
}

.reference-link {
  color: #fbd784;
  text-decoration: none;
}

.faq-wrap {
  max-width: 800px;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.faq-privacy-content {
  background-color: #fff;
  position: static;
  overflow: hidden;
}

.answer {
  color: #454545;
  margin: 25px;
}

.answer.fine-print {
  margin-top: -15px;
}

.faq-question-bar {
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  text-decoration: none;
  display: flex;
}

.faq-question-bar.date {
  justify-content: flex-end;
}

.faq-wrapper {
  margin-bottom: 40px;
  display: flex;
}

.body-full {
  min-height: 100vh;
  flex-direction: column;
  display: flex;
}

.icon-question {
  width: 60px;
  height: 60px;
  background-color: #fbd784;
  border-radius: 50vh;
  flex: none;
  margin-right: 24px;
  padding: 12px;
}

.estimator-grid {
  grid-column-gap: 16px;
  grid-row-gap: 0;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.contact-text-area {
  min-height: 290px;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #fff;
  border-radius: 12px;
  margin-top: 18px;
  margin-bottom: 18px;
}

.div-block-38 {
  margin-bottom: 51px;
}

.profile-pic {
  width: 36px;
  height: 36px;
  // background-image: url('../images/JamesJ_1JamesJ.jpg');
  background-position: 50%;
  background-size: 36px;
  border-radius: 50vh;
}

.logo-block {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 36px;
  display: flex;
}

.setup-inner {
  padding-top: 18px;
  padding-bottom: 0;
  display: block;
  overflow: hidden;
}

.text-field-5 {
  height: 36px;
  max-width: 96px;
  border: 1px solid #454545;
  border-radius: 12px;
  padding-left: 12px;
}

.garage {
  padding-top: 6px;
  padding-bottom: 18px;
}

.garage.setup {
  width: 60%;
}

.image-review-wrapper {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.icon-rv-placeholder {
  width: 96px;
  height: 96px;
  padding-top: 12px;
}

.placeholder-pic {
  width: 100%;
  height: 100%;
  min-height: 240px;
  background-color: #d8d6d6;
  justify-content: center;
  align-items: center;
  display: flex;
}

.image-review-inner {
  flex-direction: column;
  display: flex;
}

.rv-setup-outer {
  justify-content: space-between;
  margin-top: 36px;
  display: flex;
}

.default-link {
  color: var(--primary);
  align-self: center;
  padding-left: 24px;
  text-decoration: none;
}

.delete-save-default-wrapper {
  justify-content: flex-end;
  align-items: stretch;
  padding-top: 36px;
  display: flex;
}

.nav-bar-spacer {
  width: 100%;
  height: 72px;
  display: none;
  position: static;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.heading-17 {
  align-self: center;
}

.sticky-container {
  z-index: 5;
  flex-direction: column;
  flex: 1;
  display: flex;
  position: static;
}

.accordion-all {
  width: 65%;
  height: 100%;
  padding-left: 20px;
}

.navigation-wrapper {
  border: 1px solid #d8d6d6;
  flex-direction: column;
  padding: 20px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 108px;
}

.form-wrapper {
  flex-direction: column;
  justify-content: center;
  margin: 35px 25px;
  display: flex;
}

.form-wrapper.content {
  flex-direction: row;
  justify-content: flex-start;
}

.form-wrapper.content.guest {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-content: stretch;
  align-items: start;
  justify-items: center;
  display: grid;
}

.accordion-outer {
  border: 1px solid #d8d6d6;
  margin-bottom: 35px;
  display: block;
}

.accordion-content {
  padding-left: 10px;
  padding-right: 10px;
  position: static;
  overflow: hidden;
}

.accordion-title {
  border-bottom: 1px solid #d8d6d6;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px 10px 25px;
  display: flex;
}

.status-block {
  max-height: 48px;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
}

.status-block:hover {
  border-bottom: 2px solid var(--primary);
  padding-bottom: 10px;
}

.accordion-all-title {
  border-bottom: 1px solid #d8d6d6;
  justify-content: flex-end;
  align-items: center;
  margin-top: 36px;
  margin-bottom: 35px;
  padding: 25px 25px 28px;
  display: flex;
}

.accordion-all-title.cnt-cash {
  margin-top: 12px;
}

.paragraph {
  color: #fff;
  letter-spacing: 1.5px;
  font-family: rift-soft, sans-serif;
  font-size: 10px;
  font-weight: 600;
}

.required-pill {
  height: 22px;
  background-color: var(--primary);
  border-radius: 50vh;
  justify-content: center;
  align-items: center;
  padding: 3px 8px;
  display: block;
}

.accordion-status-wrapper {
  align-items: center;
  display: flex;
}

.icon-check {
  width: 22px;
  justify-content: center;
  align-items: center;
  padding-top: 6px;
  display: block;
}

.optional-rift-soft {
  color: var(--primary);
  letter-spacing: 1.5px;
  font-family: rift-soft, sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.margin-two-percent {
  margin-left: 2%;
  margin-right: 2%;
}

.waypoint-wrapper {
  margin-bottom: 18px;
}

.waypoint-wrapper.hide {
  display: none;
}

.icon-down {
  width: 18px;
}

.waypoint-function {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 18px;
  padding-right: 18px;
  display: flex;
}

.waypoint-function.hide {
  display: none;
}

.icon-up {
  width: 18px;
}

.icon-trash-wrapper {
  width: 18px;
  margin-bottom: -2px;
  margin-left: 18px;
  margin-right: 18px;
  position: static;
  top: 18px;
  bottom: auto;
  left: auto;
  right: 0%;
  transform: translate(0);
}

.icon-trash-wrapper:hover {
  transform: scale(1.25) translate(0);
}

.icon-trash-wrapper.image {
  margin-left: 0;
  margin-right: 0;
  position: static;
}

.icon-trash-wrapper.caption {
  margin-bottom: 0;
  margin-left: 12px;
  margin-right: 0;
}

.direction-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.month-wrapper {
  width: auto;
  max-width: 650px;
  align-self: center;
}

.delete-wrapper {
  align-items: center;
  display: flex;
}

.month-blackout-outer {
  justify-content: center;
  align-items: center;
  display: flex;
}

.tax-wrapper {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: flex;
}

.tax-information-grid {
  grid-column-gap: 12px;
  grid-row-gap: 0px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 3fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.tax-inner {
  width: 100%;
}

.profile-image-wrapper {
  width: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.dragula-container {
  width: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  padding-top: 18px;
  padding-bottom: 18px;
  display: grid;
}

.image-card {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.image-card-caption-wrapper {
  align-items: center;
  display: flex;
}

.dropdown-list {
  overflow: scroll;
}

.dropdown-list.w--open {
  max-height: 170px;
  overflow: auto;
}

.information-icon {
  margin-bottom: 6px;
}

.information-icon.regular,
.information-icon.paragraph {
  margin-bottom: 0;
}

.title-information-wrapper {
  display: flex;
}

.info-wrapper {
  flex: none;
  margin-left: 6px;
}

.info-wizard-wrapper {
  display: none;
}

._18px {
  padding-top: 18px;
}

.wizard-paragraph {
  line-height: 1.3em;
}

.wizard-paragraph.emphasis {
  color: var(--primary);
  padding-top: 6px;
  font-weight: 600;
}

.icon-visa {
  width: 36px;
  margin-top: -6px;
  margin-right: 12px;
  padding-top: 0;
}

.icon-mastercard {
  width: 36px;
  margin-right: 12px;
}

.credit-card-wrapper {
  display: flex;
}

.icon-amex {
  width: 48px;
  margin-top: 3px;
  margin-right: 12px;
}

.icon-discover {
  width: 48px;
  padding-top: 2px;
}

.icon-nav {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.status-block-inner {
  align-items: center;
  display: flex;
}

.outer-toggle-wrapper {
  border-bottom: 1px solid #d8d6d6;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  display: flex;
}

.outer-toggle-wrapper.no-line {
  border: 1px #000;
}

.div-block-47 {
  flex: 0 auto;
  padding-right: 24px;
}

.messages-outer {
  height: 360px;
  border: 1px solid #454545;
  border-radius: 12px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.form-inner {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: row;
  grid-template-rows: auto;
  grid-template-columns: 3fr 1fr;
  grid-auto-columns: 1fr;
  align-self: center;
  align-items: center;
  display: grid;
}

._36px {
  padding-top: 36px;
}

.icon-paypal {
  width: 84px;
  margin-left: 6px;
  margin-right: 6px;
}

.checkbox-field-horizontal {
  display: flex;
}

.logo-cnt-cash {
  width: 130px;
}

.toggle-outer {
  justify-content: flex-end;
  display: flex;
}

.cnt-cash-outer {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
  display: flex;
}

.trinidad-semibold {
  color: var(--primary);
  font-weight: 600;
}

.right-align {
  text-align: right;
}

.icon-computer {
  width: 48px;
}

.icon-tablet,
.icon-phone {
  width: 36px;
}

.device-history {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  border-bottom: 1px solid #d8d6d6;
  grid-template-rows: auto;
  grid-template-columns: 0.5fr 1fr 0.5fr;
  grid-auto-columns: 1fr;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 0;
  display: grid;
}

.device-history.no-line {
  border: 1px #000;
}

.badge-wrapper {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.form-2 {
  margin-top: 72px;
}

.div-block-48 {
  display: flex;
}

.heading-18 {
  margin-bottom: 0;
}

.div-block-49 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 12px;
  display: flex;
}

.icon-verified {
  width: 24px;
  margin: 6px 9px 6px 6px;
}

.icon-superhost,
.icon-reviews {
  width: 24px;
  margin: 6px;
}

.profile-inner {
  margin-left: 36px;
  display: flex;
}

.profile-text-block {
  padding-top: 6px;
  padding-left: 9px;
}

.icon_classb {
  width: 360px;
}

.icon_classb.smaller {
  width: 160px;
}

.rv-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.user-profile-rv-setup {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-items: center;
  display: flex;
}

.div-block-50 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 0.25fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.icon-subtraction {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.icon-addition {
  width: 24px;
  height: 24px;
  margin-left: 12px;
}

.selection-inner {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.selection-outer {
  grid-column-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 4fr 1fr;
  grid-auto-columns: 1fr;
  padding: 12px;
  display: grid;
}

.image-9 {
  margin-bottom: 12px;
}

.trinidad-n {
  color: var(--primary);
}

.checkout-status-outer {
  height: 48px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.checkout-status-inner {
  align-self: center;
  align-items: center;
  padding-top: 6px;
  padding-left: 6px;
  padding-right: 6px;
  display: flex;
}

.checkout-status {
  padding-bottom: 6px;
}

.form-a-la-carte {
  margin-top: 18px;
  margin-bottom: 12px;
}

.selection-block {
  margin-bottom: 3px;
  display: flex;
}

.additional-price {
  color: var(--primary);
  font-weight: 700;
}

.map-filter-menu {
  width: 254px;
  height: 38px;
  background-color: #fff;
  border: 1px solid #454545;
  border-radius: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  position: absolute;
  top: 36px;
  bottom: auto;
  left: auto;
  right: 36px;
}

.poi-activities {
  width: 36px;
  height: 36px;
  padding: 6px;
}

.poi-activities:hover {
  background-color: #ff7300;
}

.map-pin {
  width: 48px;
  height: 48px;
  background-color: #fff;
  border: 1px solid #454545;
  border-radius: 50vh;
  flex: none;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-top: 0;
  margin-left: 6px;
  padding-top: 12px;
  padding-left: 14px;
  padding-right: 14px;
  display: block;
  position: relative;
  left: auto;
  overflow: visible;
}

.map-pin:hover {
  background-color: #454545;
}

.poi-dump-station {
  width: 36px;
  height: 36px;
  padding: 6px;
}

.poi-dump-station:hover {
  background-color: #56235d;
}

.poi-rest-stops {
  width: 36px;
  height: 36px;
  padding: 8px;
}

.poi-rest-stops:hover {
  background-color: #bc6c25;
}

.poi-wifi {
  width: 36px;
  height: 36px;
  padding: 8px;
}

.poi-wifi:hover {
  background-color: #009f4f;
}

.poi-fresh-water {
  width: 36px;
  height: 36px;
  padding: 8px;
}

.poi-fresh-water:hover {
  background-color: #00acdc;
}

.poi-lpg {
  width: 36px;
  height: 36px;
  padding: 6px;
}

.poi-lpg:hover {
  background-color: #d00000;
}

.filter-hide {
  width: 36px;
  height: 36px;
  padding: 6px;
}

.filter-hide:hover {
  background-color: #454545;
}

.filter-hide.tippy {
  display: block;
}

.offsite-activities-wrapper {
  width: 252px;
  background-color: #fff;
  border: 1px solid #454545;
  display: none;
  position: absolute;
  top: 84px;
  bottom: auto;
  left: auto;
  right: 36px;
}

.icon-arrowhead {
  width: 24px;
  height: 24px;
  background-image: url('/img/wf/icon-arrowhead.svg');
  background-position: 50%;
  background-size: cover;
  transform: rotate(180deg);
}

.nav-menu {
  justify-content: space-between;
  display: flex;
}

.navigation-filter {
  background-color: #fff;
  border-bottom: 1px solid #d8d6d6;
  justify-content: center;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
}

.navigation-small {
  z-index: 2000;
  width: 100%;
  height: 72px;
  background-color: #fff;
  border-bottom: 1px solid #d8d6d6;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5%;
  padding-right: 1.5%;
  display: none;
  position: fixed;
  top: 0;
}

.navigation-outer {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.categories-outer {
  width: 540px;
  background-color: #fff;
  border: 1px solid #454545;
  padding: 36px;
  position: relative;
}

.categories-outer.cards {
  margin-top: 48px;
}

.activities-outer {
  background-color: #fff;
  border: 1px #454545;
  flex: none;
  overflow: hidden;
}

.filter-menu-outer {
  display: flex;
}

.poi-pin-inner {
  grid-column-gap: 0;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.poi-pin-outer {
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

.poi-icon-activities {
  padding: 18px;
}

.poi-icon-activities:hover,
.poi-icon-activities:active {
  color: #fff;
  background-color: #ff7300;
}

.poi-icon-dump-station {
  padding: 18px;
}

.poi-icon-dump-station:hover,
.poi-icon-dump-station:active {
  color: #fff;
  background-color: #56235d;
}

.poi-icon-rest-stops {
  padding: 18px;
}

.poi-icon-rest-stops:hover,
.poi-icon-rest-stops:active {
  color: #fff;
  background-color: #bc6c25;
}

.poi-icon-wifi {
  padding: 18px;
}

.poi-icon-wifi:hover,
.poi-icon-wifi:active {
  color: #fff;
  background-color: #009f4f;
}

.poi-icon-fresh-water {
  padding: 18px;
}

.poi-icon-fresh-water:hover,
.poi-icon-fresh-water:active {
  color: #fff;
  background-color: #00acdc;
}

.poi-icon-lpg {
  padding: 18px;
}

.poi-icon-lpg:hover,
.poi-icon-lpg:active {
  color: #fff;
  background-color: #d00000;
}

.offsite-activities-poi-inner {
  border-top: 1px solid #d8d6d6;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 18px;
  display: flex;
}

.offsite-poi-inner,
.pin-address-inner {
  overflow: hidden;
}

.link-block {
  color: var(--primary);
  font-style: italic;
  text-decoration: none;
}

.categories-categories-inner {
  display: block;
  overflow: hidden;
}

.categories-wrapper {
  max-height: 80vh;
  overflow: auto;
}

.close-x {
  flex: none;
  position: absolute;
  top: 36px;
  bottom: auto;
  left: auto;
  right: 36px;
}

.close-x._24px {
  top: 24px;
  right: 24px;
}

.close-x-svg {
  width: 18px;
  height: 18px;
  background-image: url('/img/wf/icon-close.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  display: inline-block;
}

.heading-20 {
  margin-right: 24px;
}

.poi-outer {
  flex: 1;
  display: flex;
}

.poi-details-card {
  width: 540px;
  max-height: 80%;
  background-color: #fff;
  border: 1px solid #000;
  padding: 36px;
  position: relative;
  overflow: auto;
}

.poi-details-card.cards {
  margin-top: 48px;
}

.poi-profile-outer {
  flex-flow: column;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-end;
  padding-top: 20px;
  display: flex;
}

.poi-header {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.poi-1 {
  height: 240px;
  // background-image: url('../images/scotchpinesgolf.jpg');
  background-position: 50%;
  background-size: cover;
}

.poi-2 {
  // background-image: url('../images/scotchpinesgolf2.jpg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.poi-images {
  grid-column-gap: 0;
  grid-row-gap: 12px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: stretch;
  display: grid;
}

.poi-phone {
  padding-top: 6px;
}

.review-wrap {
  justify-content: space-between;
  display: flex;
}

.div-block-69 {
  flex-direction: column;
  align-items: center;
  padding-top: 72px;
  display: flex;
}

.review-outer {
  flex-direction: column;
  display: flex;
}

.save-wrapper {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border: 1px solid #d8d6d6;
  flex-direction: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  padding: 20px;
  display: grid;
}

.save-wrapper.module {
  border-style: none;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px 0 0;
  display: none;
}

.reviews-outer {
  width: 100%;
  max-width: 540px;
  background-color: #fff;
  border: 1px solid #454545;
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 36px;
}

.div-block-70 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-bottom: 12px;
  display: grid;
}

.reviews-selection {
  border-bottom: 1px solid #d8d6d6;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0 0;
  font-weight: 400;
  display: flex;
}

.reviews-selection.no-line {
  border-bottom-style: none;
}

.selection {
  width: 36px;
  height: 36px;
  border: 1px solid #707070;
  border-radius: 50vh;
  margin-bottom: 12px;
}

.selection:active {
  background-color: var(--primary);
  border-bottom-style: none;
}

.back-arrow {
  width: 16px;
  height: 24px;
  color: #454545;
  transform: rotate(90deg);
}

.save-exit-wrapper {
  justify-content: space-between;
  margin-bottom: 24px;
  display: flex;
}

.save-quit {
  justify-content: flex-end;
  font-weight: 600;
  display: flex;
}

.skip {
  justify-content: flex-end;
  margin-top: 12px;
  font-weight: 600;
  display: flex;
}

.text-span-12 {
  font-size: 18px;
  font-weight: 700;
}

.response-pill {
  border: 1px solid #454545;
  border-radius: 50vh;
  margin: 4px;
  padding: 6px 18px;
}

.response-pill:active {
  color: #fff;
  background-color: #454545;
}

.response-copy {
  text-align: center;
  letter-spacing: 1.5px;
  font-family: rift-soft, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.response-inner {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.response-details-inner {
  border-bottom: 1px solid #d8d6d6;
  padding-top: 18px;
  padding-bottom: 18px;
}

.response-details-inner.no-tp {
  border: 1px #000;
  padding-top: 0;
}

.rating-trigger {
  justify-content: center;
  align-items: center;
  display: flex;
}

.response-outer {
  overflow: hidden;
}

.review-rating-outer {
  margin-top: 18px;
  margin-bottom: 6px;
}

.review-special-outer {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.icon-review-landscape {
  border: 1px solid #d8d6d6;
  border-radius: 50vh;
  padding: 18px;
}

.icon-review-amenities,
.icon-review-communication {
  border: 1px solid #d8d6d6;
  border-radius: 50vh;
  padding: 9px 18px;
}

.icon-review-cleanliness {
  border: 1px solid #d8d6d6;
  border-radius: 50vh;
  padding: 18px 24px 18px 18px;
}

.icon-reviews-alacarte {
  border: 1px solid #d8d6d6;
  border-radius: 50vh;
  padding: 18px;
}

.icon-review-hospitality {
  border: 1px solid #d8d6d6;
  border-radius: 50vh;
  padding: 9px 18px;
}

.review-special-inner {
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
}

.special-copy {
  color: var(--primary);
  text-align: center;
  padding-top: 9px;
  font-size: 18px;
  font-weight: 600;
}

.boondocking-wrapper {
  margin-bottom: 12px;
  display: flex;
}

.options-inner {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex: 0 auto;
  grid-template-rows: auto;
  grid-template-columns: 0.5fr 0.5fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.category-inner {
  flex: 1;
  align-items: center;
  display: flex;
}

.boondocking-copy {
  letter-spacing: 1.5px;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
}

.div-block-72 {
  margin-top: 24px;
}

.donation-option-outer {
  grid-column-gap: 10px;
  grid-row-gap: 20px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.caretaker-outer {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  display: grid;
}

.page-center-outer {
  flex-direction: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
}

.toggle-2 {
  z-index: 2;
  width: 26px;
  height: 26px;
  background-color: #fff;
  border-radius: 100px;
  margin-top: 2px;
  margin-left: 2px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.active-overlay-2 {
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #fff;
  border-radius: 100px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: -3px 0 3px #454545;
}

.bottom-line-2 {
  width: 12px;
  height: 2px;
  background-color: #454545;
  border-radius: 5px;
  margin-top: -2px;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: rotate(-45deg);
}

.top-line-2 {
  width: 12px;
  height: 2px;
  background-color: #454545;
  border-radius: 5px;
  margin-top: 0;
  transform: rotate(45deg);
}

.semi-bold-2 {
  color: #454545;
  letter-spacing: -0.2px;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  text-decoration: none;
}

._24-px-font-2 {
  text-align: center;
  margin-right: 9px;
  padding-right: 0;
  font-size: 24px;
  font-weight: 700;
}

.listing-details-wrapper {
  width: 100%;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  grid-template-rows: auto auto auto;
  grid-template-columns: 0.25fr 2fr 3.25fr;
  grid-auto-columns: 1fr;
  margin-bottom: 24px;
  display: grid;
}

.listing-options-inner {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: end;
  display: grid;
}

.add-listing-wrapper {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.filter-listing-wrapper {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 0.25fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.icon-boondock {
  width: 24px;
}

.icon-boondock._36-px {
  width: 36px;
}

.add-new-listing-outer {
  width: 540px;
  background-color: #fff;
  padding: 24px;
  position: relative;
}

.add-new-listing-inner {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.add-new-listing-card {
  border: 1px solid #d8d6d6;
  border-radius: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 24px;
  padding: 18px;
  display: flex;
}

.icon-listing {
  width: 18px;
}

.icon-duplicate {
  width: 24px;
}

.app-download-outer {
  height: 60px;
  grid-column-gap: 18px;
  grid-row-gap: 0px;
  flex: 1;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  justify-items: center;
  margin-bottom: -36px;
  display: grid;
}

.app-download-outer.app {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  margin-bottom: 0;
}

.apple-store,
.google-play {
  height: 60px;
}

.app-button {
  transform-style: preserve-3d;
  padding-left: 6px;
  padding-right: 6px;
}

.app-button:hover {
  transform: scale3d(1none, 1none, 1none);
}

.app-logo {
  height: 72px;
  margin-top: -4px;
  margin-bottom: 36px;
}

.icon-donation {
  width: 24px;
  height: 24px;
  margin-top: 6px;
  margin-bottom: 6px;
  padding-top: 4px;
}

.icon-direction {
  width: 18px;
  height: 18px;
  margin-top: 6px;
  margin-bottom: 6px;
  padding-top: 4px;
}

.icon-rating {
  width: 18px;
  height: 18px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.rate-direction-donation-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rate-direction-donation-wrapper.poi {
  display: none;
}

.user-image-outer {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.rating-outer {
  display: flex;
}

.photo-text {
  letter-spacing: 1.5px;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
}

.upload-photo-outer {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  text-align: center;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.upload-photo-wrapper {
  border: 1px solid #d8d6d6;
  padding: 24px;
}

.textarea {
  min-height: 160px;
}

.filter-grid {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-top: 9px;
  display: grid;
}

.filter-option-outer {
  width: 36px;
  height: 36px;
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 50vh;
  padding: 8px;
}

.filter-option-outer.poi-icon-activities:active {
  background-color: #ff7300;
}

.filter-option-outer.poi-icon-dump-station:hover {
  background-color: #56235d;
}

.filter-option-outer.poi-icon-rest-stops:hover {
  background-color: #bc6c25;
}

.filter-option-outer.poi-icon-wifi:hover {
  background-color: #009f4f;
}

.filter-option-outer.poi-icon-fresh-water:hover {
  background-color: #00acdc;
}

.filter-option-outer.poi-icon-lpg:hover {
  background-color: #d00000;
}

.location-pin {
  width: 48px;
  height: 48px;
  background-color: #fff;
  border: 1px solid #454545;
  border-radius: 50vh;
  padding-top: 12px;
  padding-left: 9px;
  padding-right: 12px;
  position: absolute;
  top: auto;
  bottom: 24px;
  left: 24px;
  right: auto;
}

.map-filter-outer {
  width: 302px;
  margin-right: 0;
  position: absolute;
  top: 27px;
  bottom: auto;
  left: auto;
  right: 36px;
  overflow: hidden;
}

.map-filter-flex-wrap {
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
  overflow: hidden;
}

.rift-h3 {
  color: #fbd784;
  text-align: center;
  letter-spacing: 2px;
  padding-left: 18px;
  font-family: rift-soft, sans-serif;
  font-size: 30px;
  font-weight: 600;
}

.rift-h3.trinidad {
  color: var(--primary);
  font-weight: 700;
}

.div-block-76 {
  text-align: center;
  margin-top: 36px;
}

.card-status {
  width: 50%;
  background-color: #fff;
  align-items: center;
  margin-top: 12px;
  padding: 4px;
  display: flex;
}

.card-status-copy {
  letter-spacing: 1.5px;
  padding-left: 4px;
  font-family: rift-soft, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.icon-requested {
  width: 28px;
  justify-content: center;
  align-items: center;
  margin-top: -11px;
  padding-top: 0;
  padding-bottom: 0;
  display: block;
}

.approval-wrapper {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  margin-top: 5px;
  display: grid;
}

// .cnt-button-2 {
//   height: 36px;
//   min-width: 96px;
//   color: #454545;
//   letter-spacing: 1.5px;
//   background-color: #fff;
//   border: 1px solid #454545;
//   border-radius: 50vh;
//   flex-direction: row;
//   flex: none;
//   justify-content: center;
//   align-items: center;
//   margin-top: 18px;
//   padding-left: 18px;
//   padding-right: 18px;
//   font-family: rift-soft, sans-serif;
//   font-weight: 600;
//   text-decoration: none;
//   display: flex;
// }

// .cnt-button-2:active {
//   color: #fff;
//   background-color: #454545;
//   border-color: rgba(0, 0, 0, 0);
// }

// .cnt-button-2.trip {
//   flex: 1;
//   margin-top: 0;
// }

// .cnt-button-copy-2 {
//   height: 36px;
//   max-width: 100%;
//   letter-spacing: 1.5px;
//   border: 1px #454545;
//   border-radius: 0;
//   flex: none;
//   justify-content: center;
//   align-items: center;
//   font-family: rift-soft, sans-serif;
//   font-weight: 600;
//   line-height: 1em;
//   text-decoration: none;
//   display: flex;
// }

// .cnt-button-copy-2:active {
//   color: #fff;
// }

.income-outer {
  margin-top: 9px;
  margin-bottom: 9px;
}

.line-item {
  justify-content: space-between;
  display: flex;
}

.guest-text-area {
  min-height: 120px;
  border: 1px solid var(--primary);
  border-radius: 12px;
  margin-top: 18px;
}

.message-form-outer {
  justify-content: space-between;
  display: flex;
}

.message-form-inner {
  flex: 1;
  padding-right: 18px;
}

.user-link-block {
  color: #454545;
  margin-top: 18px;
  text-decoration: none;
}

.user-link-block.horizontal {
  display: flex;
}

.user-communication-section {
  border: 1px #000;
  border-top: 1px solid #d8d6d6;
  padding-top: 18px;
  padding-bottom: 18px;
}

.user-communication-form {
  margin-bottom: 0;
}

.profile-outer {
  width: auto;
  border: 1px solid #d8d6d6;
  flex: 0 auto;
  padding: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 108px;
}

.profile-content-outer {
  margin-top: 36px;
}

.user-info-inner {
  flex-direction: column;
  justify-content: center;
  padding-left: 18px;
  display: flex;
}

.heading-21 {
  margin-bottom: 0;
}

.h3-profile {
  margin-top: 0;
  margin-bottom: -12px;
}

.h3-profile.provided {
  margin-bottom: 0;
}

.profile-icon-status-outer {
  align-items: center;
  margin-left: 28px;
  display: flex;
}

.profile-icon {
  width: 18px;
  padding-top: 6px;
}

.profile-copy {
  margin-left: 12px;
}

.private-listing-image {
  height: 75%;
  background-image: url('/img/wf/CurbNTurf_1.jpg');
  background-position: 50%;
  background-size: cover;
  position: relative;
}

.site-name-title {
  margin-bottom: 0;
}

.private-name-location-inner {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.private-listings-info {
  height: 25%;
  background-color: #fff;
  border: 1px solid #454545;
  border-top-style: none;
  flex-direction: column;
  justify-content: space-between;
  padding: 6px;
  display: flex;
}

.rift-soft-card {
  letter-spacing: 1.5px;
  padding-top: 5px;
  font-family: rift-soft, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1em;
}

.rift-soft-card.private {
  color: var(--primary);
  margin-bottom: 6px;
  padding-left: 0;
}

.rift-soft-card.m-6px {
  margin-right: 6px;
}

.rv-setup-grid {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.rv-icon {
  margin-top: 17px;
  margin-bottom: 17px;
}

.rv-stats-wrap {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 18px;
  display: flex;
}

.div-block-81 {
  justify-content: center;
  align-items: baseline;
  display: flex;
}

.user-listing {
  border-top: 1px solid #d8d6d6;
  padding-top: 18px;
  padding-bottom: 18px;
}

.user-rv-setup {
  border: 1px #000;
  border-top: 1px solid #d8d6d6;
  padding-top: 18px;
  padding-bottom: 18px;
}

.user-reviews {
  border-top: 1px solid #d8d6d6;
  padding-top: 18px;
}

.private-listing-outer {
  width: 288px;
  height: 288px;
}

.div-block-82 {
  justify-content: space-between;
  display: flex;
}

.italic-text-2 {
  color: var(--primary);
}

.div-block-83 {
  justify-content: center;
  display: flex;
}

.swipper-wrapper {
  width: 20em;
  height: 20em;
  max-width: 20em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
}

.swipper-wrapper._100 {
  width: 100%;
  height: 100%;
  max-width: 100%;
  width: 100%;
  height: 100%;
  max-width: 100%;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.swiper {
  z-index: 1;
  width: 100%;
  position: relative;
}

.swiper-wrapper {
  display: flex;
}

.swiper-slide {
  width: auto;
  margin-left: auto;
  margin-right: auto;
  padding-left: 6px;
  padding-right: 6px;
  position: relative;
}

.icon-save-2 {
  width: 18px;
  height: 18px;
  flex: 1;
  margin-bottom: 0;
  padding-top: 4px;
  position: relative;
}

.location-copy {
  letter-spacing: 1.5px;
  font-family: rift-soft, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1em;
}

.location-copy.private {
  color: var(--primary);
}

.poi-outer-2 {
  flex: 1;
  align-items: flex-end;
  display: flex;
}

.boondocking-poi-outer {
  flex-direction: column;
  display: flex;
}

.favorite-outer {
  width: 36px;
  height: 36px;
  background-color: #fff;
  border: 1px solid #454545;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  padding: 5px 3px 3px;
  display: flex;
}

.poi-boondocking-name-inner {
  padding-top: 0;
  padding-left: 6px;
  padding-right: 6px;
}

.html-embed-6 {
  flex: 1;
  padding-top: 0;
  display: block;
  transform: rotate(0);
}

.poi-add-fav-outer {
  align-items: flex-start;
  margin-top: 4px;
  display: flex;
  overflow: hidden;
}

.boondocking-poi-card {
  width: 288px;
  height: 72px;
  border: 1px solid #454545;
  display: flex;
  position: static;
  top: 50%;
  left: 38%;
}

.listing-boondocking-poi-info-inner {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.listing-boondocking-poi {
  width: 288px;
  height: 72px;
  border: 1px solid #707070;
  display: flex;
}

.listing-boondocking-poi-image {
  width: 72px;
  height: 72px;
  background-image: url('/img/wf/CurbNTurf_1.jpg');
  background-position: 50%;
  background-size: cover;
  background-attachment: scroll;
  border: 1px #454545;
  flex: none;
  padding-top: 0;
}

.white-paragraph {
  color: #fff;
}

.white-paragraph.b-20px {
  margin-top: 36px;
  font-size: 20px;
  font-weight: 600;
}

.dropdown-outer {
  width: 320px;
  border: 1px solid #d8d6d6;
  border-top-style: none;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  position: relative;
}

.dropdown-outer.placement {
  margin-top: 72px;
  margin-right: 1.5%;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: auto;
  right: 0%;
}

.dropdown-content {
  width: auto;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  text-decoration: none;
  display: flex;
  position: static;
  overflow: visible;
}

.dropdown-content:hover {
  border-bottom: 2px solid var(--primary);
  padding-bottom: 8px;
}

.dropdown-header {
  border: 1px #000;
  align-items: center;
  padding: 10px;
  display: block;
  position: static;
}

.trinidad-dropdown {
  color: var(--primary);
  margin-bottom: 0;
  margin-right: 0;
}

.swiper-slide-2 {
  width: 12em;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.listing-private-image {
  height: 75%;
  background-image: url('/img/wf/CurbNTurf_1.jpg');
  background-position: 50%;
  background-size: cover;
}

.listing-private-info {
  height: 25%;
  border: 1px solid #454545;
  border-top-style: none;
  flex-direction: column;
  justify-content: space-between;
  padding: 6px;
  display: flex;
}

.listing-private-card {
  width: 288px;
  height: 288px;
  border: 1px #454545;
  position: static;
  top: 33%;
  left: 38%;
}

.private-add-fav-outer {
  margin-top: 4px;
  display: flex;
  overflow: hidden;
}

.div-block-84 {
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.div-block-85 {
  border-bottom: 1px solid #454545;
  justify-content: flex-start;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
}

.grow-form-block {
  margin-top: 96px;
  margin-bottom: 48px;
}

.affiliate-text-field {
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border: 1px #000;
  border-bottom: 2px solid #fff;
  flex: 1;
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  font-weight: 400;
}

.grow-estimator-grid {
  grid-column-gap: 0px;
  grid-row-gap: 12px;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  padding-top: 24px;
}

.div-block-87 {
  display: flex;
}

.text-block-22 {
  flex: 0 auto;
  margin-top: 6px;
  margin-right: 9px;
  font-size: 24px;
  font-weight: 400;
}

.text-block-23 {
  margin-top: 6px;
  margin-left: 9px;
  margin-right: 9px;
  font-size: 24px;
  font-weight: 400;
}

.div-block-88 {
  display: flex;
}

.text-block-24 {
  margin-top: 6px;
  margin-left: 9px;
  font-size: 24px;
}

.div-block-89 {
  display: flex;
}

.text-block-25 {
  margin-top: 6px;
  margin-right: 9px;
  font-size: 24px;
}

.text-block-26 {
  margin-top: 6px;
  margin-left: 9px;
  font-size: 24px;
}

.difference-wrapper {
  justify-content: center;
  align-items: center;
  margin-top: -4px;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

.riftsoft-trinidad {
  color: var(--primary);
  letter-spacing: 1.5px;
  margin-bottom: 6px;
  font-family: rift-soft, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.nightly-pricing-outer {
  grid-column-gap: 0px;
  grid-row-gap: 12px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 2fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: start;
  display: grid;
}

.difference-icon {
  width: 24px;
  height: 24px;
  flex: none;
  margin-right: 3px;
}

.equals-wrapper {
  width: 24px;
  height: 24px;
  text-align: center;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  padding-left: 0;
  display: block;
}

.element-outer {
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  margin-right: 5px;
  display: flex;
}

.element-outer.left {
  justify-content: flex-end;
}

.element-outer.rh {
  margin-left: 5px;
}

.discount-price-outer {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.discount-price-inner {
  align-items: center;
  margin-top: -15px;
  display: flex;
}

.month-blackout-inner {
  flex: 1;
}

.gift-friend-outer {
  width: 50%;
  position: absolute;
}

.reservation-outer {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  border-bottom: 1px solid #454545;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  padding-top: 9px;
  padding-bottom: 9px;
  display: grid;
}

.user-profile-rating {
  flex-direction: column;
  display: flex;
}

.username {
  margin-bottom: 0;
}

.stay-status-inner {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  padding: 3px 16px 13px;
  display: grid;
}

.confirmation-outer {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  opacity: 1;
  border: 1px solid #d8d6d6;
  border-radius: 6px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 0.75fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  padding: 9px;
  display: grid;
  box-shadow: 0 0 0 -20px #707070;
}

.stay-details-outer {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border: 1px solid #d8d6d6;
  border-radius: 6px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 0.5fr;
  grid-auto-columns: 1fr;
  justify-content: stretch;
  padding: 12px;
  display: grid;
}

.request-status-inner {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.communication-inner {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border: 1px solid #d8d6d6;
  border-radius: 6px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  padding: 12px;
  display: grid;
}

.div-block-96 {
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.guest-info-inner,
.stay-details-inner {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.div-block-98 {
  color: #fbd784;
}

.text-block-27 {
  letter-spacing: 1.5px;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
}

.banner-outer {
  z-index: 100;
  width: 100%;
  max-width: 1440px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 80px;
  padding-right: 80px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.banner-inner {
  width: 100%;
  height: 72px;
  color: #fff;
  background-color: #e3530d;
  justify-content: center;
  align-items: center;
  padding-left: 80px;
  padding-right: 80px;
  display: flex;
}

.videoask-wrapper {
  min-height: 100%;
  min-width: 100%;
  border-radius: 0;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: block;
}

.header-paragraph {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.4em;
}

.trinidad-bg {
  color: #454545;
  background-color: #e3530d;
  background-image: url('/img/wf/Farm-Animals_Pattern_NoBG.svg');
  background-position: 50%;
  background-size: auto;
  flex: 1;
}

.convention-wrapper {
  width: 100%;
  max-width: 1440px;
  min-width: 992px;
  margin-top: 120px;
  margin-bottom: 280px;
  padding-left: 80px;
  padding-right: 80px;
}

.convention-outer {
  width: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: #fff;
  border-radius: 12px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  padding: 40px;
  display: block;
}

.cuddle-puppy-image {
  background-image: url('/img/wf/Frenchie_Trinidad.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.convention-options-wrapper {
  border: 1px solid #e3530d;
  border-radius: 6px;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  display: flex;
}

.convention-option-outer {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.convention-form {
  grid-column-gap: 16px;
  grid-row-gap: 0px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.convention-form-block {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.convention-icon-outer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.paragraph-large {
  font-size: 20px;
  line-height: 1.6;
}

.paragraph-large.emphasis {
  font-size: 24px;
  font-weight: 600;
}

.paragraph-large.meet {
  margin-bottom: 20px;
  display: block;
}

.convention-inner-top {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  display: flex;
}

.rp-logo {
  width: 72px;
  height: 72px;
  background-image: url('/img/wf/RP_Logo_Final_Trinidad.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
}

.convetion-logo-outer {
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.convetion-icon-inner {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}

.nav-button-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 6px;
  display: flex;
}

.countdown-wrapper {
  background-color: #fff;
  border: 1px solid #e3530d;
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 60px;
  display: flex;
}

.countdown-number-container {
  min-width: 160px;
  text-align: center;
  padding-left: 36px;
  padding-right: 36px;
}

.countdown-number {
  color: #170f49;
  font-size: 90px;
  font-weight: 700;
  line-height: 94px;
}

.countdown-number.number {
  color: #e3530d;
}

.countdown-number-title {
  color: #454545;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin-top: 4px;
  font-family: rift-soft, sans-serif;
  font-weight: 700;
  line-height: 20px;
}

.two-points {
  color: #e3530d;
  font-size: 90px;
  line-height: 100px;
}

.countdown-inner {
  align-self: center;
  display: flex;
}

.quick-stack {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  margin-bottom: 60px;
  padding: 0;
}

.hh-container {
  width: 90%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.card-cell {
  width: 100%;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: #fff;
  border-radius: 20px;
  flex: 1;
  justify-content: center;
  padding: 60px;
}

.text-subtitle {
  color: #5f6773;
  text-align: center;
  font-weight: 400;
}

.hero-feature-cell {
  text-align: center;
  background-color: #fff;
  border-radius: 20px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  padding: 30px 40px;
  display: flex;
}

.hero-features-cell-wrapper {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex: 1;
  padding: 0;
}

.content-wrapper-harvesthost {
  grid-row-gap: 30px;
  flex-direction: column;
  display: flex;
}

.feature-cell {
  width: 100%;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: #fff;
  border-radius: 20px;
  flex: 1;
  justify-content: center;
  padding: 60px 60px 40px;
}

.success-text {
  font-weight: 600;
}

.form-field-2col {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.form-radio-label {
  margin-bottom: 0;
}

.form-radio {
  align-items: center;
  margin-bottom: 0;
  padding-left: 1.125rem;
  display: flex;
}

.form-field-wrapper {
  position: relative;
}

.form-input {
  height: auto;
  min-height: 2.75rem;
  color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #707070;
  margin-bottom: 0;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.6;
}

.form-input:focus {
  border-color: #000;
}

.form-input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.form-input::placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.form-input.is-text-area {
  height: auto;
  min-height: 11.25rem;
  border-color: #707070;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  overflow: auto;
}

.error-text {
  color: #e23939;
}

.form-radio-2col {
  grid-column-gap: 1.5rem;
  grid-row-gap: 0.875rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.form-radio-icon {
  width: 1.125rem;
  height: 1.125rem;
  min-height: 1.125rem;
  min-width: 1.125rem;
  cursor: pointer;
  border: 1px solid #707070;
  border-radius: 100px;
  margin-top: 0;
  margin-left: -1.125rem;
  margin-right: 0.5rem;
}

.form-radio-icon.w--redirected-checked {
  background-color: #fff;
  background-image: none;
  border-width: 6px;
  border-color: #000;
}

.form-radio-icon.w--redirected-focus {
  box-shadow: none;
  border-color: #000;
  margin-top: 0;
}

.grain-container {
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(142deg, rgba(27, 1, 2, 0.5) 10%, rgba(255, 255, 255, 0.5));
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

.hh-image {
  width: 100%;
  background-image: url('/img/wf/AdobeStock_164016813_1920x1280.jpg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  border-radius: 20px;
  flex: 1;
}

.hh-image._2 {
  background-image: url('/img/wf/AdobeStock_85668924_1920x1275.jpg');
}

.hh-image._3 {
  background-image: url('/img/wf/AdobeStock_296386260_1080x691.jpg');
  background-position: 97%;
}

.hh-image._4 {
  background-image: url('/img/wf/AdobeStock_282260949_1920x1280.jpg');
}

.hh-image._5 {
  background-image: url('/img/wf/AdobeStock_120877296_1920x1280.jpg');
}

.hh-image._6 {
  background-image: url('/img/wf/AdobeStock_161100901_1920x1440.jpg');
}

.toa-video {
  border: 1px #000;
  border-radius: 20px;
}

.host_form-block {
  flex-direction: column;
  align-self: stretch;
  align-items: stretch;
  margin-bottom: 0;
}

.host_form {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

form-input-hh {
  height: auto;
  min-height: 2.75rem;
  color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
  margin-bottom: 0;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.6;
}

.form-input-hh:focus {
  border-color: #000;
}

.form-input-hh::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.form-input-hh::placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.form-input-hh.is-select-input {
  color: #454545;
  background-image: none;
  border-color: #707070;
}

.image-icon {
  color: #000;
}

.banner-subheading {
  justify-content: space-around;
  margin-bottom: 148px;
  font-size: 16rem;
  display: flex;
}

.card-content-outer {
  color: #fff;
  background-color: #e3530d;
  border-radius: 40px;
  padding: 48px 60px;
  position: relative;
}

.card-content-outer.nandor {
  background-color: #465d50;
  padding-top: 48px;
  padding-bottom: 48px;
}

.overflow-hidden {
  overflow: hidden;
}

.button-icon {
  width: 58px;
  height: 58px;
  background-color: #e7c2d4;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.button-icon.left {
  width: 60px;
  height: 60px;
  background-color: #fbd784;
}

.button-icon.right {
  width: 60px;
  height: 60px;
  min-width: auto;
  background-color: #fbd784;
}

.banner-test-item {
  align-items: center;
  display: flex;
}

.content-container {
  width: 100%;
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
  padding: 6rem 2% 0;
  overflow: hidden;
}

.content-container.day-benefits {
  display: none;
}

.content-container.bottom {
  padding-bottom: 4rem;
}

.btn-wrapper {
  z-index: 0;
  display: inline-block;
  position: relative;
}

.banner-test-item-title {
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.banner-paragraph {
  max-width: 330px;
  color: #fff;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  align-items: flex-start;
  margin-bottom: 0;
  font-family: rift-soft, sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.banner-hero-image-wrap {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%);
}

.banner-button {
  justify-content: center;
  align-items: center;
}

.banner-title {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 5rem;
}

.button-color {
  color: #000;
  letter-spacing: 1.5px;
  font-family: rift-soft, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
}

.button-primary {
  z-index: 6;
  background-color: #fbd784;
  border-radius: 100px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 19px 24px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.button-primary:hover {
  box-shadow: 0 0 #000;
}

.button-hover-wrap {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-end;
  display: flex;
}

.button-content {
  display: flex;
}

.banner-button-content {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.button-text {
  z-index: 1;
  color: #fff;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-family: rift-soft, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  position: relative;
  overflow: hidden;
}

.treatment-category-single-item {
  z-index: 0;
  color: #000;
  background-color: #fbd784;
  border-radius: 30px;
  flex-direction: column;
  justify-content: space-between;
  padding: 48px 36px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.button-arrow {
  margin-left: 4px;
}

.treatment-category-wrap {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.treatment-content {
  margin-bottom: 90px;
}

.category-title {
  z-index: 1;
  color: #000;
  margin-bottom: 18px;
  font-size: 28px;
}

.button-orange {
  width: 150px;
  height: 60px;
  color: #fff;
  background-color: #e3530d;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  padding-top: 19px;
  padding-bottom: 19px;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.treatment-icon {
  z-index: -1;
  padding-left: 10.5rem;
  position: absolute;
  top: auto;
  bottom: 3%;
  right: 0%;
}

.challenge-course-days-area {
  z-index: 1;
  width: 100%;
  background-color: #465d50;
  border-radius: 60px;
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: none;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.challenge-course-days-area._1 {
  padding-top: 6rem;
  padding-bottom: 6rem;
  display: block;
}

.item-content {
  height: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  display: flex;
}

.test-title {
  z-index: 1;
  color: #000;
  margin-top: 14px;
  margin-bottom: 18px;
  font-size: 28px;
  font-weight: 400;
}

.seal-price {
  color: #e3530d;
  margin-right: 4px;
  font-size: 36px;
  font-weight: 600;
  line-height: 36px;
  text-decoration: line-through;
  display: inline-block;
}

.view-all-button-wrap {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.primary-button-2 {
  z-index: 0;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 16px;
  text-decoration: none;
  transition: all 0.2s;
  display: flex;
  position: relative;
  overflow: hidden;
}

.primary-button-2:hover {
  transform: scale(1.05);
}

.primary-button-2.border {
  border: 1px solid #231f1e;
}

.sticky {
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2%;
  padding-right: 2%;
  position: -webkit-sticky;
  position: sticky;
  top: 120px;
}

.challenge-course-inner {
  width: 100%;
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 60px;
  padding-right: 60px;
  overflow: hidden;
}

.discount-title {
  letter-spacing: 0.2em;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
}

.single-item-card {
  z-index: 0;
  background-color: #fff;
  border-radius: 30px;
  padding: 42px 32px;
  position: relative;
  overflow: hidden;
}

.single-item-card.style-02 {
  color: #000;
  background-color: #fff;
  padding: 48px 36px;
}

.single-item-card.style-01 {
  background-color: #fff;
}

.view-all-button {
  color: #fff;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-family: rift-soft, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 110%;
}

.view-all-icon-image {
  margin-left: 4px;
}

.section-title {
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: 0;
}

.section-title.color-white {
  color: #fff;
  text-transform: none;
  font-size: 3rem;
}

.discount-tag {
  color: #fff;
  background-color: #465d50;
  border-radius: 4px;
  margin-bottom: 18px;
  padding: 7px 14px;
  display: inline-block;
}

.discount-tag.style-02 {
  color: #000;
  background-color: #fbd784;
  text-decoration: none;
}

.discount-tag.style-01 {
  color: #fff;
  background-color: #e3530d;
}

.regular-price {
  color: #707070;
  font-size: 18px;
  font-weight: 400;
  line-height: 130%;
  text-decoration: line-through;
  display: inline-block;
}

.regular-price.style-02 {
  color: #c1ae8b;
}

.price-tag {
  margin-top: 110px;
}

.section-title-wrap.grid-item {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.section-title-wrap.grid-item.section-padding {
  grid-template-columns: 2fr 1fr;
  margin-bottom: 24px;
}

.scroll-section-wrapper {
  z-index: 2555;
  width: 100%;
  min-height: 250vh;
  border-radius: 60px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 4rem;
}

.section-content-wrap {
  grid-column-gap: 23px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.tabs {
  height: 50rem;
  background-color: #e3530d;
  border-radius: 60px;
  padding: 5rem 60px;
  position: relative;
}

.lab-best-deals-wrap {
  grid-column-gap: 24px;
  grid-row-gap: 36px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.expertise-inner-wrap {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  grid-template:
    '.'
    / 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.dot {
  width: 6px;
  height: 6px;
  background-color: #ff6712;
  border-radius: 100px;
  justify-content: flex-end;
  align-items: center;
  margin-right: 4px;
}

.live-event-single-item {
  z-index: 0;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: #d8d6d6;
  border-radius: 40px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  padding: 48px 48px 36px;
  display: grid;
  position: relative;
}

.recurringplot-logo {
  z-index: -1;
  position: absolute;
  top: auto;
  bottom: 29px;
  left: auto;
  right: 22px;
}

.stream-time {
  color: #e3530d;
  align-self: flex-start;
  font-size: 16px;
  font-weight: 400;
  line-height: 110%;
}

.expertise-subtitle {
  color: #000;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
}

.stream-content-wrap {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
  position: static;
  top: auto;
  bottom: 36px;
  left: 48px;
  right: 0%;
}

.counter-single-item {
  z-index: 0;
  background-color: #d8d6d6;
  border-radius: 40px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 48px 48px 36px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.video-lightbox {
  z-index: 0;
  color: #0e0f18;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  position: relative;
}

.icon-video {
  width: 24px;
}

.expertise-tag {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.64);
  border: 0.5px solid #fcf9e8;
  border-radius: 4px;
  justify-content: flex-start;
  align-items: center;
  padding: 7px 14px;
  display: flex;
}

.expertise-title {
  color: #000;
  margin-top: 16px;
  margin-bottom: 10px;
}

.tag-wrap {
  display: inline-block;
}

.video-content-wrap {
  display: flex;
}

.grid-two {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.paragraph-expertise {
  max-width: 330px;
  margin-bottom: 60px;
  font-size: 18px;
  line-height: 1.6rem;
}

.paragraph-expertise.meet {
  line-height: 1.7rem;
  display: none;
}

.count-image {
  z-index: -1;
  position: static;
  top: 20px;
  bottom: auto;
  left: auto;
  right: 20px;
}

.events-tag {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.64);
  border: 0.5px solid rgba(252, 249, 232, 0.5);
  border-radius: 4px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 44px;
  padding: 7px 14px;
  display: flex;
}

.podcast-single-item {
  z-index: 0;
  color: #000;
  background-color: #fbd784;
  border-radius: 40px;
  padding: 48px 48px 36px;
  position: relative;
  overflow: hidden;
}

.video-button {
  width: 84px;
  height: 84px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.image-10 {
  display: none;
}

.banner-content-grid {
  grid-column-gap: 36px;
  grid-row-gap: 12px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-around;
  display: grid;
}

.banner-content-grid._3up {
  grid-template-columns: 1fr 1fr 1fr;
}

.banner-content-inner {
  flex: 1;
  padding: 0;
}

.login-form {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.challenge-header {
  color: #000;
  background-color: #fbd784;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  padding: 48px 36px;
  display: flex;
}

.background-black {
  color: #fff;
  background-color: #000;
  border-radius: 40px;
  padding: 60px;
}

.line-div {
  height: 2px;
  background-color: #fff;
  margin-top: 18px;
  margin-bottom: 18px;
}

.image-profile {
  display: block;
}

.instructor-inner {
  grid-column-gap: 6rem;
  grid-row-gap: 6rem;
  grid-template-rows: auto;
  grid-template-columns: 1.25fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.image-11,
.image-12 {
  width: 100%;
}

.challenge-emphasis {
  text-align: center;
  letter-spacing: 1.2px;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-family: rift-soft, sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 1;
}

.challenge-center {
  justify-content: center;
  align-items: center;
  padding-top: 36px;
  display: flex;
}

.benefits-image {
  z-index: -1;
}

.benefits-image-outer {
  flex-direction: column;
  flex: 0 auto;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.challenge-course-footer {
  grid-column-gap: 18px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 0.25fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.cnt-logo {
  width: 60px;
}

.challenge-footer-inner {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.challenge-course-grid {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-top: 60px;
  margin-bottom: 60px;
  display: grid;
}

.challenge-course-card {
  background-color: #fff;
  flex-direction: column;
  display: flex;
}

.challenge-course-bot {
  color: #000;
  letter-spacing: 1.5px;
  padding: 18px;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
}

.day-link {
  color: #e3530d;
  flex: 1;
  text-decoration: none;
  display: block;
}

.day-link:hover {
  transform: translate(6px);
}

.challenge-course-title-wrap {
  display: flex;
}

.img-complete {
  width: 24px;
}

.title-challenge-complete {
  color: #008001;
}

.title-challenge {
  display: block;
}

.hidden {
  display: none;
}

.challenge-course-nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.challenge-course-vid {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 0;
}

.attachment-pdf {
  color: #fff;
  letter-spacing: 1.5px;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
  text-decoration: none;
}

.copy-image {
  font-style: italic;
}

.course-nav-outer {
  display: flex;
}

.single-download {
  background-color: #465d50;
  border-radius: 40px;
  padding: 36px 48px;
}

.h3-riftsoft {
  color: #fbd784;
  letter-spacing: 1.5px;
  font-family: rift-soft, sans-serif;
  font-weight: 600;
}

.login-modal-component {
  z-index: 99;
  width: 100vw;
  height: 100vh;
  color: #000;
  background-color: rgba(10, 32, 70, 0.7);
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.text-align-center {
  text-align: center;
  display: flex;
}

.login-modal-close-button {
  width: 2rem;
  height: 2rem;
  color: #000;
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.max-width-large {
  width: 100%;
  max-width: 48rem;
}

.text-size-medium {
  color: #0a2046;
  object-fit: fill;
  margin-bottom: 32px;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2rem;
}

.login-modal-content-wrapper {
  width: 100%;
  height: auto;
  max-height: 85vh;
  max-width: 48rem;
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 3rem 3rem 2rem;
  overflow: hidden;
}

.show-mobile-landscape {
  display: none;
}

.login-modal-background-overlay {
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.div-block-99 {
  text-align: center;
}

.div-block-100 {
  position: static;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

@media screen and (max-width: 991px) {
  h2 {
    line-height: 1em;
  }

  .toggle-wrap.rv {
    margin-right: 0;
  }

  .form-block.search {
    width: 100%;
    margin-bottom: 0;
  }

  .cnt-button.filter {
    height: 48px;
    border-style: none;
    flex: none;
    justify-content: flex-end;
    margin-left: 0;
    margin-right: 0;
  }

  .cnt-button.filter:active {
    color: #fff;
    background-color: var(--primary);
    border-radius: 0;
  }

  .cnt-button.filter-button {
    flex: none;
  }

  .cnt-button.listing {
    margin-bottom: 0;
  }

  .cnt-button.categories,
  .cnt-button.m-18px {
    flex: none;
  }

  .cnt-button-icon {
    width: 18px;
    display: block;
  }

  .cnt-button-icon._24px {
    width: 24px;
  }

  .semi-bold {
    padding-right: 18px;
  }

  .wizard-nav-wrapper {
    justify-content: space-between;
  }

  .wizard-nav-wrapper.categories {
    justify-content: flex-end;
  }

  .wizard-info-card {
    min-width: 280px;
    display: none;
  }

  // keep for now
  .spacer {
    height: 48px;
    display: none;
  }

  .text-input.search {
    width: 100%;
    margin-top: 12px;
  }

  .toggle-wrapper.setup {
    width: 70%;
  }

  .price-current-status {
    border-top-right-radius: 50vh;
    border-bottom-right-radius: 50vh;
  }

  .price-section {
    flex-direction: row;
  }

  .sum-wrapper {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .landowner-wrapper {
    width: 100%;
    flex-direction: row;
    margin-bottom: 0;
  }

  .landowner-wrapper.listing {
    flex-direction: row;
    margin-top: 12px;
    padding-top: 0;
  }

  .rift-soft {
    color: #454545;
    text-decoration: none;
  }

  .rift-soft.list {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 6px;
    font-size: 16px;
    text-decoration: none;
  }

  .info-icon {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .nav-search-wrapper {
    flex: 1;
  }

  .nav-bar {
    display: block;
    position: fixed;
    top: 0;
    bottom: auto;
    left: 0;
    right: 0;
  }

  .icon-search {
    margin-top: -1px;
    padding-bottom: 0;
  }

  .search-wrapper {
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    margin-left: 0;
    margin-right: 0;
  }

  .brand {
    margin-left: 18px;
    margin-right: 18px;
    display: block;
  }

  .cnt-hero-wrapper {
    height: 42.43vw;
    align-items: flex-start;
  }

  .cnt-button-secondary.hero {
    top: auto;
    bottom: 20px;
    left: 20px;
    right: auto;
  }

  .price-strip {
    padding-left: 18px;
    padding-right: 18px;
  }

  .price-strip.mobile {
    height: 36px;
  }

  .booking-section {
    flex-direction: row;
    align-items: flex-start;
    display: none;
  }

  .listing-content-section {
    overflow: hidden;
  }

  .user-image {
    flex: 1;
  }

  .user-image.menu {
    width: 60px;
    height: 60px;
  }

  .listing-content-inner-wrapper {
    width: 100%;
    padding-right: 0;
    overflow: hidden;
  }

  .hero-image {
    width: 100%;
    height: 42.43vw;
  }

  .secondary-image-wrapper {
    display: none;
  }

  .hero-search {
    width: 100%;
  }

  .content-center.grow {
    display: block;
  }

  .content-center.affiliate-white {
    display: none;
  }

  .button {
    margin-top: 0;
  }

  .button.trinidad {
    max-width: 360px;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .button.trinidad.center {
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .trinidad.center {
    text-align: left;
    margin-bottom: 12px;
  }

  .overlay.white {
    position: relative;
  }

  .device-content-width-wrapper {
    margin-top: 2.5%;
    margin-bottom: 2.5%;
  }

  .small-device {
    display: none;
  }

  .nandor-bg {
    align-items: stretch;
  }

  .center.trinidad {
    text-align: center;
  }

  .listing-content-wrapper {
    flex-direction: column;
    padding-left: 2%;
    padding-right: 2%;
  }

  .booking-card-mobile {
    display: block;
  }

  .booking-bottom-bar {
    z-index: 1000;
    grid-row-gap: 6px;
    background-color: #fff;
    flex-direction: row;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    justify-content: space-between;
    align-items: center;
    padding: 0 2% 2%;
    display: grid;
    position: fixed;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }

  .booking-bottom-bar-spacer {
    height: 136px;
  }

  .booking-bottom-section {
    display: block;
  }

  .booking-button {
    width: 60%;
    align-self: center;
    margin-left: -2%;
    margin-right: -2%;
  }

  .button-wrapper-grid {
    grid-template-rows: auto;
  }

  ._3-up-grid.garage {
    padding-top: 8px;
  }

  .footer-inner {
    padding-left: 2.5%;
    padding-right: 2.5%;
    overflow: hidden;
  }

  .footer-wrapper {
    padding-top: 24px;
    padding-bottom: 24px;
    overflow: hidden;
  }

  .footer-grid {
    grid-column-gap: 24px;
  }

  .navigation-link {
    height: 48px;
    background-color: #fff;
    padding-top: 14px;
    padding-bottom: 12px;
  }

  .small-device-menu {
    width: 80vw;
    background-color: #fff;
    border-bottom: 1px solid #454545;
    border-right: 1px solid #d8d6d6;
    display: block;
    position: absolute;
    top: 0;
    bottom: auto;
    left: 0;
    right: auto;
    overflow: scroll;
  }

  .menu-button {
    height: 100%;
  }

  .menu-button:active {
    color: #fff;
    background-color: var(--primary);
  }

  .menu-button.w--open {
    height: 100%;
    background-color: var(--primary);
  }

  .hamburger-menu {
    margin-top: 4px;
  }

  .filter-outer {
    width: 60px;
    height: 72px;
    border: 1px #000;
    border-bottom: 1px solid #d8d6d6;
    flex: 0 auto;
    display: none;
  }

  .filter-wrapper {
    flex: 1;
  }

  .results-wrapper {
    margin-top: 72px;
    position: relative;
  }

  .map-wrapper {
    display: none;
  }

  .map-wrapper.map-only,
  .map {
    display: block;
  }

  .private-listing-card,
  .listing-image,
  .listing-information-wrapper {
    max-width: none;
  }

  .amenities-inner {
    max-height: 100%;
    overflow: hidden;
  }

  .icon-save {
    flex: 1;
    margin-top: 6px;
  }

  .save-icon-wrapper {
    border-radius: 6px 0 0 6px;
    padding-top: 0;
    padding-right: 0;
    display: flex;
    bottom: 20px;
    left: auto;
    right: 0;
  }

  .save-icon-wrapper.listing {
    bottom: 20px;
  }

  .listing-results-large {
    width: 100%;
    max-width: 991px;
    min-width: 736px;
    grid-column-gap: 18px;
    grid-row-gap: 18px;
    grid-template-rows: 212px;
    grid-template-columns: auto;
    grid-auto-columns: 1fr;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    position: relative;
  }

  .private-listing-information-selected {
    max-width: none;
  }

  .photo-wrapper {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
  }

  .filter-dates-wrapper {
    display: none;
  }

  .months-inner-wrapper {
    flex-direction: row;
  }

  .filter-price-wrapper,
  .filter-distance-wrapper {
    display: none;
  }

  .current-location-wrapper.small-device {
    display: flex;
  }

  .filter-my-rv-wrapper {
    display: none;
  }

  .profile-wrapper.small-device {
    display: flex;
  }

  .amenities.small-device {
    display: block;
  }

  .filter-amenities-wrapper {
    display: none;
  }

  .filter-activities-wrapper {
    margin-top: 0;
    display: none;
    position: absolute;
  }

  .small-device-inner.small-device {
    display: flex;
  }

  .map-button {
    width: 84px;
    height: 36px;
    background-color: #fff;
    border: 1px solid #454545;
    border-left-style: none;
    border-top-right-radius: 50vh;
    border-bottom-right-radius: 50vh;
    align-items: center;
    margin-top: 36px;
    padding: 6px 0 0 18px;
    text-decoration: none;
    display: flex;
    position: absolute;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: auto;
  }

  .icon-map {
    width: 18px;
  }

  .list-button {
    height: 36px;
    background-color: #fff;
    border: 1px solid #454545;
    border-left-style: none;
    border-top-right-radius: 50vh;
    border-bottom-right-radius: 50vh;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    display: flex;
    position: absolute;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: auto;
  }

  .icon-list {
    width: 18px;
    margin-top: 4px;
  }

  .affiliate-wrapper {
    grid-template-columns: 140px 140px 140px 140px 140px;
  }

  .italic-text {
    display: none;
  }

  .cnt-logo-wrapper {
    width: 48px;
  }

  .content-outer {
    max-width: none;
  }

  .count {
    font-size: 60px;
    top: -62%;
    left: -4%;
  }

  .count._2,
  .count._3,
  .count._4 {
    left: -5%;
  }

  .nav-link-hero.nav {
    align-self: auto;
  }

  .content-hero {
    max-width: 680px;
    margin-top: 36px;
  }

  .content-hero.learn {
    padding-left: 90px;
    padding-right: 90px;
  }

  .nav-link-wrapper.guest {
    background-color: #465d50;
  }

  .content-grid {
    grid-column-gap: 32px;
    grid-row-gap: 64px;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .content-grid.copy {
    grid-row-gap: 0;
  }

  .content-grid.fit {
    margin-bottom: 50px;
  }

  .content-wrapper.content {
    margin-top: 40px;
    margin-bottom: 50px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .content-wrapper.content.short {
    align-items: center;
    margin-top: 60px;
    margin-bottom: 60px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .content-wrapper.content.large {
    margin-bottom: 120px;
  }

  .content-wrapper.nav {
    align-items: flex-end;
  }

  .content-wrapper.hero {
    align-items: center;
    margin-top: 0%;
  }

  .mg {
    top: 30%;
  }

  .breakpoint-wrapper.five {
    min-height: 360px;
    align-items: end;
  }

  .content-section {
    padding-top: 0;
  }

  .content-section.nandor-background {
    margin-top: 0;
  }

  .grid-photo.app {
    background-image: url('/img/wf/CurbNTurf-App-Phone-mockup-4k.png');
  }

  .login-wrapper {
    justify-content: center;
  }

  .gradient-section {
    margin-top: 0;
  }

  .content-inner-wrapper {
    grid-row-gap: 16px;
  }

  .content-inner-wrapper.regular {
    padding-top: 18px;
  }

  .content {
    margin-top: 0;
  }

  .right {
    width: 100%;
    height: 50%;
  }

  .section-12 {
    flex-direction: column;
    padding-left: 80px;
  }

  .secondary-hero-wrapper,
  .secondary-hero-wrapper.host {
    margin-top: 0;
  }

  .secondary-hero-wrapper.host-more {
    background-position: 0%;
  }

  .hero-inner {
    height: 42.43vw;
  }

  .promo-copy-wrapper {
    top: 0;
    bottom: auto;
    left: 0;
    right: auto;
  }

  .learn-more {
    font-size: 16px;
  }

  .app-class-a {
    padding-top: 180px;
  }

  .app-camper {
    padding-top: 240px;
  }

  .three-column-grid {
    grid-row-gap: 50px;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
  }

  .faq-question-wrap {
    color: #454545;
  }

  .text-field-5 {
    max-width: 108px;
  }

  .garage {
    padding-top: 8px;
  }

  .garage.setup {
    width: 70%;
  }

  .image-review-wrapper {
    grid-template-columns: 1fr;
  }

  .placeholder-pic {
    min-height: 280px;
  }

  .rv-setup-outer {
    margin-top: 18px;
  }

  .nav-bar-spacer {
    display: block;
  }

  .accordion-all {
    width: 100%;
    padding-left: 0;
  }

  .month-wrapper {
    width: auto;
  }

  .info-wizard-wrapper {
    display: none;
  }

  .menu-inner {
    height: 84px;
    align-items: center;
    display: flex;
  }

  .div-block-57 {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
    display: flex;
  }

  .link-3 {
    color: var(--primary);
    font-size: 14px;
    text-decoration: none;
  }

  .heading-19 {
    margin-bottom: 0;
  }

  .user-profile-outer {
    border-bottom: 1px solid #d8d6d6;
    padding-left: 18px;
    padding-right: 18px;
  }

  .rift-soft-trinidad {
    color: var(--primary);
    letter-spacing: 1.5px;
    font-family: rift-soft, sans-serif;
    font-size: 18px;
    font-weight: 600;
  }

  .icon-arrowhead {
    color: var(--primary);
    background-image: url('/img/wf/arrowhead.svg');
  }

  .rift-soft-menu {
    letter-spacing: 1.5px;
    font-family: rift-soft, sans-serif;
    font-weight: 600;
    display: flex;
  }

  .icon-menu {
    width: 18px;
    height: 18px;
    color: var(--primary);
    margin-right: 12px;
  }

  .menu-link-block {
    height: 48px;
    color: #454545;
    align-items: center;
    padding-left: 18px;
    padding-right: 18px;
    text-decoration: none;
    display: flex;
  }

  .nav-menu {
    width: 80vw;
    background-color: #fff;
    margin-top: 72px;
  }

  .navigation-filter {
    border: 1px #000;
    align-items: flex-start;
    padding-top: 0;
    padding-bottom: 0;
  }

  .navigation-small {
    width: auto;
    flex: 1;
    padding-left: 0;
    padding-right: 0;
    display: none;
    position: static;
  }

  .navigation-outer {
    z-index: 2000;
    background-color: #fff;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    position: fixed;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: 0%;
  }

  .option-outer {
    width: 100%;
    height: 48px;
    border-bottom: 1px solid #d8d6d6;
    justify-content: space-between;
    align-items: center;
    padding-left: 18px;
    padding-right: 18px;
    text-decoration: none;
    display: flex;
  }

  .icon-filter {
    width: 18px;
    height: 18px;
    margin-left: 12px;
  }

  .guest-inner,
  .host-inner,
  .profile-info-inner,
  .legal-inner {
    overflow: hidden;
  }

  .categories-wrapper {
    max-height: 80%;
  }

  .close-x-svg {
    width: 18px;
    height: 18px;
  }

  .semi-bold-2 {
    padding-right: 18px;
  }

  .listing-details-wrapper {
    grid-row-gap: 16px;
    grid-template-columns: 0.25fr 1.5fr 3fr;
  }

  .add-listing-wrapper {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .filter-listing-wrapper {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    grid-template-columns: 0.25fr 1fr 1fr 1fr;
  }

  .icon-direction {
    width: 18px;
    height: 18px;
    flex: none;
  }

  .icon-rating {
    width: 24px;
    height: 24px;
    flex: none;
  }

  .rate-direction-donation-wrapper {
    display: flex;
  }

  .rate-direction-donation-wrapper.poi {
    display: none;
  }

  .cnt-button-2,
  .icon-save-2 {
    flex: 1;
  }

  .div-block-85 {
    margin-top: 72px;
  }

  .grow-form-block {
    margin-top: 0;
  }

  .grow-estimator-grid {
    grid-template-columns: 0.75fr 1fr 1fr 1fr 0.75fr;
  }

  .difference-wrapper {
    margin-top: -3px;
  }

  .discount-price-inner {
    align-items: center;
  }

  .gift-friend-outer {
    width: 100%;
  }

  .reservation-outer {
    grid-template-columns: 1.25fr 1.25fr 0.5fr;
  }

  .stay-status-inner {
    align-content: end;
    padding: 9px 18px;
  }

  .confirmation-outer,
  .stay-details-outer {
    grid-template-columns: 1fr;
  }

  .stay-details-inner {
    margin-bottom: 9px;
  }

  .banner-outer {
    top: 72px;
  }

  .convention-wrapper {
    max-width: 990px;
    min-width: 768px;
    margin-top: 60px;
    margin-bottom: 140px;
    padding-left: 60px;
    padding-right: 60px;
  }

  .convention-option-outer {
    grid-template-columns: 1fr 1fr;
  }

  .convention-form-block {
    grid-template-columns: 1fr 0.5fr;
  }

  .paragraph-large.meet {
    margin-bottom: 20px;
    display: block;
  }

  .h1-alt {
    text-align: center;
    font-size: 60px;
  }

  .countdown-wrapper {
    padding: 56px 33px;
  }

  .countdown-number-container {
    min-width: 120px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .countdown-number.number {
    font-size: 70px;
    line-height: 74px;
  }

  .countdown-number-title {
    font-size: 16px;
    line-height: 18px;
  }

  .two-points {
    font-size: 68px;
    line-height: 72px;
  }

  .feature-cell {
    padding-left: 60px;
  }

  .cell {
    flex-direction: column;
  }

  .card-content-outer {
    padding: 48px 40px;
  }

  .content-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .content-container.day-benefits {
    display: block;
  }

  .banner-hero-image-wrap {
    display: none;
  }

  .treatment-category-wrap {
    grid-template-columns: 1fr 1fr;
  }

  .treatment-icon.small {
    padding-left: 17rem;
  }

  .challenge-course-days-area,
  .sticky {
    position: static;
  }

  .challenge-course-inner {
    padding-left: 20px;
    padding-right: 20px;
  }

  .view-all-button {
    letter-spacing: 0.15em;
  }

  .scroll-section-wrapper {
    min-height: auto;
    display: none;
  }

  .section-content-wrap {
    grid-template-columns: 1fr 1fr;
  }

  .tabs {
    height: auto;
  }

  .lab-best-deals-wrap {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    grid-template-columns: 1fr;
  }

  .expertise-inner-wrap {
    grid-template-columns: 1fr;
  }

  .live-event-single-item {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: flex-start;
    display: grid;
  }

  .stream-content-wrap {
    width: auto;
    position: static;
  }

  .counter-single-item {
    justify-content: center;
    margin-bottom: 30px;
    display: none;
  }

  .grid-two {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    grid-template-rows: auto;
  }

  .paragraph-expertise.meet {
    display: none;
  }

  .count-image {
    position: static;
  }

  .banner-content-grid {
    grid-template-columns: 1fr;
  }

  .login-form {
    padding-top: 18px;
  }

  .instructor-inner {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-template-columns: 1.25fr;
  }

  .challenge-course-grid {
    grid-template-columns: 1fr 1fr;
  }

  .login-modal-component {
    display: none;
  }

  .login-modal-close-button {
    display: block;
  }

  .login-modal-content-wrapper {
    width: 90%;
    max-width: none;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .icon-embed-x {
    display: block;
  }

  .show-mobile-landscape {
    display: none;
  }

  .div-block-99 {
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 20px;
    line-height: 1.2em;
  }

  h5 {
    line-height: 1.2em;
  }

  p {
    font-size: 14px;
  }

  .toggle-wrap {
    margin-right: 0;
  }

  .heading-2 {
    padding-bottom: 0;
    font-size: 18px;
  }

  .cnt-button {
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .cnt-button.mobile {
    margin-top: 6px;
  }

  .cnt-button.filter-button {
    margin-right: 6px;
  }

  .cnt-button.hide {
    display: none;
  }

  .cnt-button-copy {
    font-size: 14px;
  }

  .cnt-button-icon,
  .cnt-button-icon.addition,
  .cnt-button-icon.substraction,
  .cnt-button-icon.addition,
  .cnt-button-icon.substraction {
    display: block;
  }

  .button-wrapper {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }

  .button-wrapper._18px {
    padding-top: 18px;
  }

  .text-field-label {
    padding-left: 9px;
    padding-right: 9px;
  }

  .text-field-rounded-left {
    width: 64px;
    flex: none;
  }

  .input-wrapper {
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 0;
  }

  .input-w-label {
    justify-content: center;
  }

  .axle-count {
    padding: 0;
  }

  .wizard-nav-btn {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .current-status {
    height: 5px;
  }

  .image {
    margin-top: 12px;
    padding-bottom: 0;
  }

  .heading-3 {
    font-size: 14px;
    line-height: 1.2em;
  }

  .toggle-wrapper.setup {
    width: 100%;
  }

  .rv-button-copy {
    font-size: 14px;
  }

  .price-section {
    flex-direction: column;
  }

  .price-section.grid {
    grid-column-gap: 6px;
    grid-row-gap: 0px;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 1fr;
  }

  .icon-w-label {
    justify-content: flex-start;
    padding-top: 18px;
  }

  .icon-w-label.grid {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .sum-wrapper {
    padding-top: 18px;
  }

  .fine-print {
    line-height: 1.4em;
  }

  .site-rule-wrapper {
    display: none;
  }

  .add-rules-wrapper {
    display: block;
  }

  .site-description {
    min-height: 240px;
  }

  .site-image-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .rift-soft {
    font-size: 16px;
  }

  .rift-soft.list {
    padding-left: 6px;
  }

  .nav-search-wrapper {
    flex: 1;
  }

  .nav-bar {
    display: block;
    position: relative;
  }

  .nav-link.trinidad {
    font-size: 14px;
    line-height: 1.2em;
  }

  .icon-search {
    margin-top: -2px;
    padding-top: 0;
  }

  .brand {
    width: 36px;
    display: none;
  }

  .cnt-button-secondary.hero {
    font-size: 12px;
    bottom: 12px;
    left: auto;
    right: 12px;
  }

  .price-strip.mobile {
    height: 36px;
  }

  .anchor-link-wrapper {
    width: auto;
  }

  .listing-content-section {
    padding-top: 12px;
    padding-bottom: 12px;
    line-height: 1.6em;
  }

  .user-image {
    width: 48px;
    height: 48px;
  }

  .user-image.reviews {
    width: 72px;
    height: 72px;
  }

  .reviews-badge {
    font-size: 14px;
  }

  .feature-row {
    margin-bottom: 0;
    font-size: 14px;
  }

  .location {
    font-size: 14px;
  }

  .secondary-image-wrapper {
    display: none;
  }

  .content-center.affiliate-white {
    display: block;
  }

  .button {
    padding: 12px 18px;
    font-size: 14px;
  }

  .button.trinidad {
    border-width: 2.5px;
    padding: 12px 18px;
    font-size: 14px;
    line-height: 1;
  }

  .button.trinidad.center {
    text-align: center;
  }

  .trinidad.nospacing {
    font-size: 16px;
  }

  .large-device {
    display: none;
  }

  .overlay {
    padding: 20px;
  }

  .overlay.white {
    margin-bottom: 0;
  }

  .overlay.modal {
    padding: 0;
  }

  .small-device-copy.money {
    background-image: url('/img/wf/airstream-inc-lzD1i1Rfbtw-unsplash.jpg');
    background-position: 80% 100%;
  }

  .device-content-width-wrapper {
    width: 80vw;
  }

  .small-device {
    display: block;
  }

  .listing-content-wrapper {
    flex-direction: column;
    padding-left: 2.5%;
    padding-right: 2.5%;
  }

  .rv-grid {
    grid-row-gap: 6px;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 1fr 1fr;
  }

  .rift-soft-copy {
    font-size: 14px;
  }

  .listing-icon-grid {
    grid-template-columns: 1fr 1fr;
  }

  .linsting-info-grid {
    grid-row-gap: 3px;
    margin-bottom: 3px;
  }

  .booking-card-mobile {
    display: none;
  }

  .booking-bottom-bar {
    z-index: 1000;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    flex-direction: column;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    justify-content: center;
    align-items: stretch;
    padding: 0 2.5% 6px;
    position: fixed;
    left: 0;
    right: 0;
  }

  .booking-bottom-section {
    display: block;
  }

  .booking-button {
    width: 60%;
  }

  ._3-up-grid.filter {
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr;
  }

  .rating-avg {
    font-size: 14px;
    display: block;
  }

  .rating-total {
    display: none;
  }

  .reviews-grid {
    grid-column-gap: 12px;
  }

  .footer-inner {
    margin-top: 0;
    padding-left: 0%;
    padding-right: 0%;
    display: block;
  }

  .footer-wrapper {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 0;
    padding: 12px 2.5%;
    display: block;
  }

  .footer-grid {
    grid-row-gap: 6px;
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr 1fr;
    align-content: stretch;
    justify-content: stretch;
  }

  .follow-wrapper {
    margin-top: 0;
    padding-top: 16px;
  }

  .menu-button {
    height: 100%;
  }

  .menu-button.guest.w--open {
    background-color: var(--primary);
  }

  .filter-outer {
    display: block;
  }

  .filter-outer.small {
    justify-content: center;
    align-items: flex-start;
    display: flex;
  }

  .full-view-wrapper {
    padding-top: 0;
  }

  .results-wrapper {
    margin-top: 72px;
  }

  .map-wrapper {
    display: none;
  }

  .map {
    display: block;
  }

  .private-listing-card {
    width: 100%;
    grid-column-gap: 0;
    grid-row-gap: 0;
    grid-template-rows: auto auto;
    grid-template-columns: 0.75fr 1.25fr;
    grid-auto-columns: 1fr;
    display: flex;
  }

  .listing-image {
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
  }

  .amenities-inner {
    padding: 12px;
    display: none;
  }

  .amenities-inner.filter {
    display: flex;
  }

  .save-icon-wrapper {
    border-radius: 0 6px 6px 0;
    bottom: 12px;
    left: 0;
    right: auto;
  }

  .save-icon-wrapper.listing {
    bottom: 12px;
  }

  .listing-results-large {
    width: 100%;
    max-width: 767px;
    min-width: 480px;
  }

  .listing-name-h3 {
    white-space: normal;
  }

  .listing-location,
  .location-price {
    font-size: 14px;
  }

  .r-chevron-wrapper,
  .l-chevron-wrapper {
    display: block;
  }

  .filter-dates-wrapper {
    margin-left: 0;
    margin-right: 0;
    display: none;
  }

  .info-inner,
  .bottom-button-wrapper {
    flex-wrap: wrap;
  }

  .bottom-button-wrapper.small-device-footer {
    padding-left: 18px;
    padding-right: 18px;
  }

  .months-inner-wrapper {
    flex-direction: column;
    grid-template-rows: auto auto;
    grid-template-columns: auto;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    align-content: stretch;
    justify-content: center;
    justify-items: stretch;
  }

  .filter-price-wrapper {
    display: none;
  }

  .static-slider-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .filter-distance-wrapper,
  .filter-my-rv-wrapper {
    display: none;
  }

  .filter-header,
  .amenities.small-device {
    color: var(--primary);
  }

  .filter-amenities-wrapper,
  .filter-activities-wrapper {
    display: none;
  }

  .activities.small-device {
    color: var(--primary);
    text-align: center;
  }

  .small-device-header {
    z-index: 999;
    height: 60px;
    background-color: #fff;
    border-bottom: 1px solid #d8d6d6;
    padding-left: 18px;
    padding-right: 18px;
    position: fixed;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: 0%;
  }

  .calendar-wrapper {
    clear: both;
    margin-top: 60px;
  }

  .days-week {
    padding-top: 6px;
  }

  .small-device-footer {
    z-index: 3;
    clear: both;
    background-color: #fff;
    border-top: 1px solid #d8d6d6;
    position: fixed;
    top: auto;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }

  .previous-month-wrapper {
    margin-right: 0;
  }

  .small-device-filter-wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }

  .icon-filter-slider {
    width: 360px;
  }

  .small-device-inner {
    align-items: center;
  }

  .small-device-inner.rv-selection {
    align-items: stretch;
  }

  .small-device-inner.small-device {
    text-align: center;
    flex-direction: column;
    display: flex;
  }

  .map-button,
  .list-button {
    margin-top: 24px;
  }

  .first-name-wrapper {
    padding-right: 0;
  }

  .name-wrapper {
    flex-direction: column;
  }

  .phone-number-wrapper {
    padding-right: 0;
  }

  .city-state-zip-wrapper {
    flex-direction: column;
  }

  .state-inner,
  .city-inner {
    padding-right: 0;
  }

  .contact-wrapper {
    flex-direction: column;
  }

  .white-centered {
    font-size: 54px;
  }

  .affiliate-title {
    margin-bottom: -1px;
  }

  .affiliate-wrapper {
    grid-template-rows: auto auto auto;
    grid-template-columns: 1.75fr 1fr 1fr;
    padding-top: 36px;
    font-size: 13px;
    line-height: 1.2em;
  }

  .affiliate-wrapper.commissions {
    grid-template-rows: auto auto;
    grid-template-columns: 0.75fr 1fr;
    align-items: center;
    justify-items: center;
    padding-top: 12px;
  }

  .italic-text {
    display: none;
  }

  .hero-section {
    height: 1000px;
  }

  .tagline {
    margin-bottom: 16px;
  }

  .gradient-hero {
    height: 200vh;
  }

  .link-to.hero {
    font-size: 14px;
  }

  .cnt-logo-wrapper {
    width: 36px;
  }

  .count,
  .count._2,
  .count._3,
  .count._4 {
    display: none;
  }

  .content-hero {
    justify-content: center;
    margin-top: 0;
    top: 40px;
  }

  .content-hero.learn.host {
    padding-top: 180px;
  }

  .nav-link-wrapper {
    display: none;
  }

  .content-grid {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .content-grid.fit {
    margin-bottom: 36px;
  }

  .hg {
    width: 100%;
    height: 600px;
    object-fit: cover;
  }

  .content-wrapper.content {
    margin-bottom: 40px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .content-wrapper.content.short {
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .content-wrapper.nav {
    padding-left: 32px;
    padding-right: 12px;
  }

  .content-wrapper.hero {
    align-items: center;
    margin-top: 0;
    padding-left: 32px;
    padding-right: 32px;
    overflow: visible;
  }

  .vg {
    width: 100%;
    height: 600px;
    object-fit: cover;
    object-position: 50% 0%;
    top: 50%;
  }

  .mg {
    width: 100%;
    height: 600px;
    object-fit: cover;
    top: 35%;
  }

  .breakpoint-wrapper.five {
    min-height: 280px;
  }

  .breakpoint-wrapper.cnt-app {
    grid-template-rows: auto;
  }

  .content-section {
    margin-top: 0;
  }

  .content-section.nandor-background {
    background-image: linear-gradient(rgba(11, 29, 38, 0), #465d50 5%);
  }

  .grid-photo.started {
    background-position: 20%;
  }

  .content-inner-wrapper.regular {
    padding-top: 0;
  }

  .section-12 {
    min-height: 600px;
    padding-left: 60px;
  }

  .footer-dark-wrapper {
    justify-content: center;
    padding: 12px 2.5%;
    display: block;
  }

  .promo-copy-wrapper {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .learn-more {
    font-size: 14px;
  }

  .app-class-a {
    padding-top: 240px;
  }

  .app-camper {
    padding-top: 220px;
  }

  .three-column-grid {
    grid-row-gap: 36px;
    grid-template-columns: 1fr;
  }

  .estimator-wrapper {
    padding-top: 0;
  }

  .rift-soft-title {
    font-size: 14px;
  }

  .faq-wrapper {
    align-self: center;
  }

  .icon-question {
    width: 48px;
    height: 48px;
    padding: 9px;
  }

  .estimator-grid {
    align-content: stretch;
    align-items: end;
  }

  .garage.setup {
    width: 70%;
  }

  .image-review-wrapper {
    grid-row-gap: 36px;
  }

  .icon-rv-placeholder {
    width: 72px;
    height: 72px;
  }

  .default-link {
    font-size: 14px;
  }

  .nav-bar-spacer {
    height: 0;
  }

  .accordion-all {
    width: 100%;
    padding-left: 0;
  }

  .form-wrapper {
    margin: 25px 15px;
  }

  .accordion-title {
    padding-left: 20px;
    padding-right: 10px;
  }

  .tax-information-grid {
    grid-column-gap: 0px;
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr 2.25fr;
  }

  .dragula-container {
    grid-template-columns: 1fr;
  }

  ._18px {
    padding-top: 12px;
  }

  .form-inner {
    grid-template-columns: 3fr 1.5fr;
    display: grid;
  }

  .map-filter-menu {
    top: 24px;
    right: 24px;
  }

  .icon-outer {
    padding-left: 24px;
  }

  .menu-link-block {
    color: #454545;
    text-decoration: none;
  }

  .navigation-filter {
    height: 100%;
  }

  .navigation-small {
    display: flex;
  }

  .navigation-outer {
    z-index: 2000;
    background-color: #fff;
    flex-direction: row;
  }

  .categories-outer {
    width: 100%;
    padding: 24px;
  }

  .categories-wrapper {
    width: 100%;
    max-height: 100%;
  }

  .close-x {
    top: 24px;
    right: 24px;
  }

  .close-x._24px {
    top: 12px;
    right: 12px;
  }

  .heading-20 {
    margin-right: 36px;
  }

  .poi-details-card.cards {
    width: 100%;
  }

  .div-block-69 {
    padding: 18px;
  }

  .save-wrapper.module {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .donation-option-outer {
    grid-column-gap: 10px;
  }

  .listing-options-inner {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
  }

  .add-listing-wrapper {
    grid-template-columns: 1fr 1fr;
  }

  .filter-listing-wrapper {
    grid-template-rows: auto;
    grid-template-columns: 0.25fr 1fr 1fr 1fr;
  }

  .add-new-listing-outer {
    width: 480px;
    padding: 12px;
  }

  .app-download-outer {
    margin-bottom: -9px;
  }

  .apple-store,
  .google-play {
    height: 48px;
  }

  .app-logo {
    height: 60px;
  }

  .map-filter-outer {
    top: 15px;
    right: 24px;
  }

  .cnt-button-2 {
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .user-link-block.horizontal {
    justify-content: center;
    align-items: center;
  }

  .div-block-85 {
    margin-top: 0;
  }

  .grow-form-block {
    margin-top: 60px;
  }

  .affiliate-text-field {
    font-size: 18px;
  }

  .grow-estimator-grid {
    grid-row-gap: 0px;
    grid-template-columns: 0.25fr 1fr 1fr 1fr 0.5fr;
  }

  .text-block-22,
  .text-block-23,
  .text-block-24,
  .text-block-25,
  .text-block-26 {
    font-size: 18px;
  }

  .difference-wrapper {
    margin-top: 0;
  }

  .nightly-pricing-outer {
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr;
  }

  .difference-icon,
  .equals-wrapper {
    width: 24px;
    height: 24px;
  }

  .reservation-outer {
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr;
  }

  .banner-outer {
    padding-left: 20px;
    padding-right: 20px;
    top: 60px;
  }

  .banner-inner {
    padding: 10px 20px;
  }

  .convention-wrapper {
    max-width: 720px;
    min-width: 480px;
    margin-top: 40px;
    margin-bottom: 80px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .paragraph-large.meet {
    margin-bottom: 40px;
    display: block;
  }

  .countdown-wrapper {
    padding-left: 29px;
    padding-right: 29px;
  }

  .countdown-number-container {
    min-width: 70px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .countdown-number.number {
    font-size: 40px;
    line-height: 59px;
  }

  .countdown-number-title {
    font-size: 14px;
    line-height: 16px;
  }

  .two-points {
    font-size: 42px;
    line-height: 50px;
  }

  .card-cell {
    padding: 40px;
  }

  .hero-feature-cell {
    width: 100%;
  }

  .cell {
    display: block;
  }

  .form-field-2col.is-mobile-1col {
    grid-template-columns: 1fr;
  }

  .hh-image {
    height: 80vw;
    align-self: auto;
  }

  .hh-image._2,
  .hh-image._3,
  .hh-image._5 {
    flex: 0 auto;
  }

  .banner-subheading {
    margin-top: 10px;
    margin-bottom: 90px;
  }

  .card-content-outer {
    border-radius: 15px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .content-container {
    padding: 6rem 15px 0;
  }

  .content-container.top {
    padding-top: 6rem;
  }

  .content-container.bottom {
    padding-bottom: 4rem;
  }

  .banner-test-item-title {
    font-size: 1.8rem;
  }

  .banner-paragraph {
    max-width: 100%;
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 26px;
  }

  .banner-button {
    display: flex;
  }

  .banner-title {
    font-size: 4rem;
  }

  .button-color {
    font-size: 14px;
  }

  .button-primary {
    padding: 14px 30px;
  }

  .banner-button-content {
    display: block;
  }

  .treatment-category-single-item {
    border-radius: 10px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .advantages-outer {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .treatment-category-wrap {
    grid-template-columns: 1fr;
  }

  .challenge-course-days-area {
    border-radius: 15px;
    padding-top: 100px;
    padding-bottom: 60px;
  }

  .primary-button-2.border.cart-button:hover {
    background-color: #f9dcdc;
    border-color: #f9dcdc;
  }

  .challenge-course-inner {
    padding-left: 15px;
    padding-right: 15px;
  }

  .single-item-card {
    border-radius: 10px;
    padding: 20px;
  }

  .view-all-button {
    font-size: 16px;
    font-weight: 400;
  }

  .price-tag {
    margin-top: 70px;
  }

  .section-title-wrap.grid-item,
  .section-title-wrap.grid-item.section-padding {
    grid-template-columns: 1fr;
    justify-items: start;
  }

  .scroll-section-wrapper {
    padding-top: 0;
    padding-bottom: 0;
  }

  .section-content-wrap {
    grid-template-columns: 1fr 1fr;
  }

  .lab-best-deals-wrap {
    grid-template-columns: 1fr;
  }

  .live-event-single-item {
    border-radius: 15px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .recurringplot-logo {
    opacity: 0.4;
  }

  .counter-single-item {
    border-radius: 15px;
    padding: 30px;
  }

  .paragraph-expertise.meet {
    display: none;
  }

  .podcast-single-item {
    border-radius: 15px;
    padding: 30px;
  }

  .banner-content-grid._3up {
    grid-template-columns: 1fr;
  }

  .challenge-header {
    padding-left: 30px;
    padding-right: 30px;
  }

  .background-black {
    border-radius: 15px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .instructor-inner {
    grid-template-columns: 1.75fr;
  }

  .challenge-emphasis {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .challenge-center {
    padding-top: 10px;
  }

  .number1,
  .number2,
  .number3 {
    padding: 4rem;
  }

  .challenge-course-grid {
    grid-template-columns: 1fr;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .single-download {
    border-radius: 15px;
  }

  .login-modal-component {
    align-items: flex-start;
  }

  .text-size-medium {
    font-size: 1.2rem;
  }

  .login-modal-content-wrapper {
    width: 100%;
    height: 100vh;
    max-height: none;
    padding-bottom: 7rem;
    padding-left: 5%;
    padding-right: 5%;
    position: relative;
    overflow: scroll;
  }

  .icon-embed-x {
    display: none;
  }

  .show-mobile-landscape {
    display: block;
    position: absolute;
    top: 0%;
    bottom: auto;
    left: auto;
    right: 0%;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 60px;
  }

  p {
    line-height: 1.6em;
  }

  .wizard-form {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
  }

  .cnt-button {
    flex-direction: row;
  }

  .cnt-button.filter {
    width: 100%;
    min-width: auto;
    border-style: none;
    justify-content: flex-end;
  }

  .cnt-button.filter-button {
    margin-right: 6px;
  }

  .cnt-button.categories {
    flex: none;
  }

  .cnt-button._60px-button {
    min-width: 60px;
  }

  .cnt-button-copy.setup {
    white-space: nowrap;
  }

  // keep for now
  .cnt-button-icon {
    display: none;
  }

  // keep for now
  .cnt-button-icon.auth {
    display: block;
  }

  // keep for now
  .cnt-button-icon.apple-auth {
    display: block;
    width: 20px;
  }

  .cnt-button-icon._24px {
    display: block;
  }

  .cnt-button-icon._24px.show-icon,
  .cnt-button-icon.show-icon {
    margin-right: 0;
    display: block;
  }

  // keep for now
  .text-field-rounded-left {
    width: 50%;
  }

  .text-field-rounded {
    width: 50%;
  }

  .text-input.trinidad {
    border-width: 1px;
  }

  .dropdown.listing {
    flex: 0 auto;
  }

  .text-field-rounded-right {
    margin-right: 0;
  }

  ._2up-dropdown-wrapper {
    grid-template-columns: 1fr;
  }

  .checkbox-field {
    padding-top: 12px;
  }

  .rift-soft {
    margin-left: 0;
    padding-bottom: 0;
  }

  // keep for now
  .auth-wrapper {
    width: 300px;
    padding-right: 18px;
    padding-left: 18px;
  }

  .white.grow {
    font-size: 48px;
    line-height: 1em;
  }

  .text-field-inner-wrapper {
    width: 80%;
    justify-content: center;
    align-items: center;
  }

  .text-block-18 {
    flex: 0 auto;
  }

  .nav-bar {
    height: 72px;
    padding-left: 2.5%;
    position: fixed;
  }

  .icon-search {
    margin-top: 0;
    padding-top: 0;
  }

  .brand {
    display: none;
  }

  .cnt-button-secondary.hero {
    padding: 6px 9px;
    display: block;
  }

  .anchor-link-wrapper {
    justify-content: center;
    overflow: hidden;
  }

  .guest-copy {
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-left: 0;
  }

  .feature-name {
    font-size: 15px;
  }

  .user-image,
  .user-image.menu {
    width: 48px;
    height: 48px;
  }

  .host-reviews {
    flex-direction: column;
  }

  .guest-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .reviews-badge-wrapper {
    margin-bottom: 20px;
  }

  .location {
    line-height: 1em;
    display: block;
  }

  .helpful-inner {
    margin-left: 10px;
  }

  .search-input {
    line-height: 1;
  }

  .button {
    letter-spacing: 1.5px;
    font-size: 12px;
  }

  .button.trinidad {
    font-size: 14px;
    line-height: 1.2;
  }

  .button.trinidad.video {
    width: 100%;
  }

  .button.trinidad.center {
    text-align: center;
  }

  .button.nandor {
    width: 100%;
    font-size: 14px;
    line-height: 1.4;
  }

  .trinidad {
    text-align: left;
  }

  .device-content-width-wrapper {
    width: 80vw;
  }

  .icon-inner-wrapper {
    justify-content: flex-start;
  }

  .listing-icon-grid {
    grid-template-columns: 1fr;
    justify-content: stretch;
    align-items: center;
    justify-items: start;
  }

  .linsting-info-grid {
    grid-template-columns: 1fr;
  }

  .booking-card-mobile {
    display: none;
  }

  .button-wrapper-grid {
    grid-row-gap: 9px;
    grid-template-columns: 1fr;
  }

  .button-wrapper-grid.filter {
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 1fr 1fr;
  }

  .button-wrapper-grid._2-column {
    grid-template-columns: 1fr 1fr;
  }

  ._3-up-grid {
    grid-row-gap: 12px;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: stretch;
    padding-bottom: 12px;
  }

  ._2-up-grid {
    grid-column-gap: 12px;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: stretch;
  }

  .copy-regular {
    font-size: 15px;
  }

  .reviews-grid {
    grid-column-gap: 0;
    grid-template-columns: 1fr;
  }

  .footer-inner {
    min-width: 0;
    padding-left: 2.5%;
    padding-right: 2.5%;
    overflow: hidden;
  }

  .footer-grid {
    grid-template-rows: auto auto auto auto auto auto auto;
    grid-template-columns: 1fr;
  }

  .copy-wrapper {
    padding-right: 0;
  }

  .navbar {
    position: static;
  }

  .small-device-menu {
    width: 80%;
    top: 60px;
    bottom: auto;
    left: 0;
    right: 0%;
  }

  .menu-button {
    border: 1px #000;
  }

  .filter-outer {
    flex: none;
  }

  .map-wrapper {
    display: none;
  }

  .map {
    display: block;
  }

  .listing-information-wrapper {
    justify-content: space-between;
  }

  .listing-name-wrapper {
    height: 50%;
    text-align: center;
    align-self: center;
  }

  .listing-price-wrapper {
    height: 50%;
  }

  .icon-save {
    margin-top: 6px;
  }

  .save-icon-wrapper {
    max-height: 100%;
    flex-direction: column;
    flex: none;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0;
    padding-bottom: 6px;
    bottom: 0;
  }

  .save-icon-wrapper.listing {
    bottom: 0;
  }

  .listing-results-large {
    max-width: 479px;
    min-width: 320px;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    padding: 12px;
  }

  .listing-name-h3 {
    white-space: normal;
  }

  .listing-name-h3.white,
  .listing-location.white {
    text-align: center;
  }

  .listing-price-inner {
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
  }

  .amenities-wrapper,
  .r-chevron-wrapper,
  .l-chevron-wrapper {
    display: none;
  }

  .photo-wrapper {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
  }

  .dates-selected.listing {
    margin-bottom: 18px;
  }

  .info-inner.listing {
    flex-direction: column;
  }

  .bottom-button-wrapper.small-device-footer {
    z-index: 8;
    justify-content: flex-end;
    padding-left: 12px;
    padding-right: 12px;
  }

  .price-range {
    font-size: 14px;
  }

  .icon-rv-camper {
    margin-right: 6px;
  }

  .current-location-wrapper.small-device {
    width: 296px;
  }

  .use-current-location {
    padding-left: 6px;
    font-size: 14px;
  }

  .payette,
  .distance {
    font-size: 14px;
  }

  .profile-wrapper.small-device {
    width: 296px;
  }

  .select-your-profile {
    font-size: 14px;
  }

  .amenities.small-device,
  .activities.small-device {
    color: var(--primary);
  }

  .small-device-header {
    padding-left: 12px;
    padding-right: 12px;
  }

  .dates-small {
    padding-top: 3px;
  }

  .days-week {
    margin-bottom: 12px;
    margin-left: 0;
    margin-right: 0;
  }

  .month {
    grid-template:
      '.' 36px
      'Area' 36px
      '.' 36px
      '.' 36px
      '.' 36px
      '.' 36px
      / 36px 36px 36px 36px 36px 36px 36px 36px;
  }

  .small-device-footer {
    padding-left: 18px;
    padding-right: 18px;
  }

  .small-device-filter-wrapper {
    padding-left: 12px;
    padding-right: 12px;
  }

  .icon-filter-slider {
    width: 296px;
  }

  .small-device-inner {
    align-items: center;
  }

  .small-device-inner.rv-selection {
    align-items: stretch;
    padding-left: 12px;
    padding-right: 12px;
  }

  .width-wrapper {
    width: 296px;
  }

  .map-button {
    margin-top: 24px;
  }

  .list-button {
    height: 36px;
    background-color: #fff;
    border: 1px solid #454545;
    border-left-style: none;
    border-top-right-radius: 50vh;
    border-bottom-right-radius: 50vh;
    align-items: center;
    padding-bottom: 0;
    padding-right: 0;
    display: flex;
  }

  .icon-list {
    width: 18px;
    margin-top: 6px;
  }

  .first-name-wrapper,
  .text-field,
  .error-message,
  .phone-number-wrapper,
  .last-name-wrapper,
  .zip-inner,
  .address-wrapper,
  .state-inner,
  .city-inner,
  .field-label {
    font-size: 14px;
  }

  .white-centered {
    font-size: 36px;
  }

  .tagline-line {
    width: 40px;
    margin-right: 16px;
  }

  .tagline-line.trinidad {
    width: 24px;
    margin-right: 12px;
  }

  .gradient-hero {
    height: 300vh;
  }

  .cnt-logo-wrapper {
    width: 36px;
  }

  .content-hero {
    max-width: 320px;
    margin-top: 0;
    padding-top: 0;
  }

  .content-hero.learn {
    padding-left: 20px;
    padding-right: 20px;
  }

  .heading-tagline {
    letter-spacing: normal;
    font-size: 12px;
  }

  .heading-tagline.trinidad {
    text-align: left;
  }

  .content-grid {
    grid-row-gap: 12px;
  }

  .hg {
    object-position: 30% 50%;
  }

  .content-wrapper.content {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .content-wrapper.content.short {
    padding-left: 10px;
  }

  .content-wrapper.nav {
    padding-left: 24px;
    padding-right: 0;
  }

  .content-wrapper.hero {
    padding-left: 24px;
    padding-right: 24px;
  }

  .vg {
    object-position: 30% 50%;
    top: 50%;
  }

  .mg {
    object-position: 30% 50%;
  }

  .breakpoint-wrapper.five {
    min-height: 220px;
  }

  .grid-photo.planning {
    background-position: 84%;
  }

  .grid-photo.app {
    background-size: cover;
  }

  .grid-photo.started {
    background-position: 19%;
  }

  .grid-photo.success {
    background-position: 85%;
  }

  .grid-photo.money {
    background-position: 35%;
  }

  .grid-photo.triptych {
    background-position: 50%;
    background-size: contain;
  }

  .content-inner-wrapper.regular {
    padding-bottom: 20px;
  }

  .content {
    width: 100%;
  }

  .right {
    min-width: 0;
  }

  .button-large-wrapper {
    grid-template-columns: 2.5fr;
  }

  .button-large-wrapper.video {
    align-items: stretch;
  }

  .secondary-hero-wrapper {
    margin-bottom: 59px;
  }

  .secondary-hero-wrapper.host {
    max-height: 100vh;
    margin-bottom: 60px;
  }

  .secondary-hero-wrapper.host-more {
    background-position: 10%;
  }

  .form-block-2 {
    width: auto;
  }

  .app-text-area {
    border-width: 1px;
  }

  .app-class-a {
    padding-top: 300px;
  }

  .app-camper {
    padding-top: 0;
    padding-bottom: 0;
  }

  .three-column-grid {
    grid-row-gap: 20px;
  }

  .rift-soft-title {
    flex: 1;
  }

  .icon-question {
    width: 36px;
    height: 36px;
    flex: none;
    padding: 6px;
  }

  .estimator-grid {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
  }

  .garage.setup {
    width: 100%;
  }

  .icon-rv-placeholder {
    width: 60px;
    height: 60px;
  }

  .rv-setup-outer {
    flex-direction: column-reverse;
  }

  .default-link {
    align-self: flex-end;
    padding-top: 18px;
  }

  .delete-save-default-wrapper {
    flex-direction: column;
    align-items: flex-end;
  }

  .add-rv-wrapper {
    align-self: flex-end;
    margin-bottom: 6px;
  }

  .nav-bar-spacer {
    height: 72px;
    // display: none;
    position: static;
  }

  .form-wrapper {
    margin: 20px 10px;
  }

  .accordion-title {
    padding-left: 20px;
    padding-right: 0;
  }

  .month-blackout-outer {
    flex-direction: row;
  }

  .tax-information-grid {
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 1fr;
  }

  .icon-nav.trip {
    overflow: hidden;
  }

  .form-inner {
    grid-template-columns: 3fr;
  }

  .map-filter-menu {
    justify-content: flex-start;
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;
    top: auto;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }

  .map-pin {
    top: 0;
    bottom: auto;
    left: auto;
    right: 0;
  }

  .offsite-activities-wrapper {
    display: none;
  }

  .heading-19,
  .rift-soft-trinidad {
    font-size: 16px;
  }

  .rift-soft-menu {
    font-size: 14px;
  }

  .menu-link-block {
    height: 36px;
  }

  .nav-menu {
    background-color: #fff;
  }

  .navigation-filter {
    z-index: 20001;
    height: auto;
    border: 1px #000;
    position: fixed;
    top: 0;
    bottom: auto;
    left: auto;
    right: 0;
  }

  .navigation-outer {
    display: flex;
  }

  .categories-outer {
    padding: 18px;
  }

  .categories-outer.cards {
    padding: 9px;
  }

  .poi-icon-activities.tippy,
  .poi-icon-dump-station.tippy,
  .poi-icon-rest-stops.tippy,
  .poi-icon-wifi.tippy,
  .poi-icon-fresh-water.tippy,
  .poi-icon-lpg.tippy {
    padding: 6px;
  }

  .poi-details-card.cards {
    padding: 9px;
  }

  .poi-profile-outer {
    order: -1;
  }

  .poi-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .div-block-69 {
    padding: 9px;
  }

  .reviews-outer {
    max-width: 460px;
    min-width: 280px;
    padding: 18px;
  }

  .review-special-outer {
    grid-template-columns: 1fr 1fr;
  }

  .boondocking-wrapper {
    flex-direction: column;
  }

  .options-inner {
    flex: 1;
    grid-template-columns: 0.5fr 0.5fr 1fr;
  }

  .category-inner {
    flex: 1;
    padding-bottom: 6px;
  }

  .donation-option-outer {
    grid-column-gap: 8px;
  }

  .caretaker-outer {
    align-items: center;
  }

  .add-listing-wrapper {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    grid-template-columns: 1fr;
  }

  .filter-listing-wrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .add-new-listing-outer {
    width: 320px;
  }

  .add-new-listing-inner {
    grid-template-columns: 1fr;
  }

  .app-logo {
    height: 60px;
  }

  .icon-donation {
    flex: none;
    margin-bottom: 0;
    padding-top: 4px;
  }

  .icon-direction {
    flex: none;
  }

  .icon-rating {
    flex: none;
    padding-top: 4px;
  }

  .rate-direction-donation-wrapper {
    flex-direction: column;
    flex: none;
    justify-content: flex-start;
    display: flex;
  }

  .map-filter-outer {
    right: 12px;
  }

  .cnt-button-2 {
    flex-direction: row;
  }

  .icon-save-2 {
    margin-top: 6px;
  }

  .favorite-outer {
    padding-top: 0;
    padding-bottom: 0;
  }

  .listing-boondocking-poi-image {
    flex: none;
  }

  .white-paragraph.b-20px {
    font-size: 16px;
  }

  .affiliate-text-field {
    font-size: 14px;
  }

  .grow-estimator-grid {
    width: 100%;
    grid-template-rows: auto auto auto auto auto auto;
    grid-template-columns: 0.25fr 1fr 1fr 1fr 1fr;
    padding-top: 0;
  }

  .text-block-22,
  .text-block-23,
  .text-block-24,
  .text-block-25,
  .text-block-26 {
    font-size: 14px;
  }

  .riftsoft-trinidad {
    margin-top: 12px;
  }

  .nightly-pricing-outer {
    grid-row-gap: 0px;
    grid-template-columns: 1fr;
    align-content: stretch;
    align-items: center;
    justify-items: center;
  }

  .bold {
    font-weight: 700;
  }

  .banner-outer {
    padding-left: 20px;
    padding-right: 20px;
  }

  .banner-inner {
    height: 96px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .bold-text {
    font-size: 14px;
  }

  .videoask-wrapper {
    width: 100%;
    min-width: 312px;
  }

  .convention-wrapper {
    max-width: 479px;
    min-width: 320px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .convention-outer {
    padding: 20px;
  }

  .paragraph-large {
    font-size: 16px;
  }

  .paragraph-large.meet {
    display: block;
  }

  .h1-alt {
    font-size: 48px;
  }

  .countdown-wrapper {
    flex-direction: column;
    margin-top: 28px;
    padding-top: 32px;
    padding-bottom: 48px;
  }

  .countdown-number-container {
    min-width: 90px;
    align-self: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .countdown-number.number {
    font-size: 68px;
    line-height: 72px;
  }

  .countdown-number-title {
    font-size: 16px;
    line-height: 17px;
  }

  .two-points {
    font-size: 52px;
    line-height: 67px;
  }

  .countdown-inner {
    flex-direction: column;
    align-items: center;
  }

  .hh-container {
    width: 85%;
  }

  .card-cell {
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
  }

  .hero-feature-cell {
    flex: 1;
    align-items: center;
    padding: 20px;
  }

  .hero-features-cell-wrapper {
    width: 100%;
    align-self: flex-start;
  }

  .feature-cell {
    border-radius: 20px;
    padding: 20px;
  }

  .form-field-2col {
    grid-template-columns: 1fr;
  }

  .card-content-outer,
  .card-content-outer.nandor {
    padding-left: 20px;
    padding-right: 20px;
  }

  .button-icon {
    width: 40px;
    height: 40px;
  }

  .button-icon.left,
  .button-icon.right {
    flex: none;
  }

  .content-container.top {
    padding-top: 4rem;
  }

  .banner-test-item-title {
    text-align: center;
  }

  .banner-button {
    flex-direction: column;
    align-items: center;
  }

  .banner-title {
    font-size: 3rem;
  }

  .button-color {
    text-align: center;
    font-size: 12px;
  }

  .button-primary {
    padding: 10px 20px;
  }

  .banner-button-content {
    display: block;
  }

  .button-text {
    font-size: 14px;
  }

  .treatment-category-single-item {
    border-radius: 10px;
    padding: 24px 20px;
  }

  .treatment-category-wrap {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-columns: 1fr;
  }

  .treatment-content {
    margin-bottom: 50px;
  }

  .category-title {
    text-align: left;
    font-size: 22px;
  }

  .treatment-icon {
    width: 100px;
  }

  .treatment-icon.small {
    width: 50%;
    padding-left: 1rem;
  }

  .challenge-course-days-area {
    padding-top: 80px;
  }

  .test-title {
    text-align: left;
    font-size: 22px;
  }

  .single-item-card.style-02 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .section-title.color-white {
    line-height: 1em;
  }

  .price-tag {
    margin-top: 50px;
  }

  .section-content-wrap {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-columns: 1fr 1fr;
  }

  .lab-best-deals-wrap {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 1fr;
  }

  .stream-time {
    padding-top: 10px;
  }

  .stream-content-wrap {
    flex-wrap: wrap;
  }

  .counter-single-item {
    padding: 30px 20px;
  }

  .video-lightbox {
    display: block;
  }

  .login-form {
    grid-template-columns: 1fr;
  }

  .challenge-header {
    padding-left: 20px;
    padding-right: 20px;
  }

  .background-black {
    padding: 48px 20px;
  }

  .instructor-inner {
    grid-column-gap: 4rem;
    grid-row-gap: 4rem;
  }

  .number1,
  .number2,
  .number3 {
    padding: 4rem;
  }

  .challenge-course-grid {
    grid-template-columns: 1fr;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .text-size-medium {
    font-size: 1.2rem;
  }

  .icon-embed-x {
    display: none;
  }

  .show-mobile-landscape {
    top: 0%;
    bottom: auto;
    left: 0%;
    right: auto;
  }
}

@import 'features/nodes';