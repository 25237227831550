.button.w-button {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 260px;
  min-width: 96px;
  padding: 18px 24px;
  border: 2px solid #fff;
  border-radius: 36px;
  background-color: transparent;
  font-family: rift-soft, sans-serif;
  color: #fff;
  font-size: 18px;
  line-height: 1;
  font-weight: 600;
  text-align: center;
  letter-spacing: 1.5px;
  text-indent: 2px;
}
